import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import ReactDrawer from "react-drawer";
import { Col, Row, Label, Input, FormGroup } from "reactstrap";
import { SpinnerCircular } from "spinners-react";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { getCustomerByDocIdAsync } from "src/helpers/backend_helper";
import useClaims from "src/hooks/useClaim";
const CustomerSchema = Yup.object().shape({
    message: Yup.string().required("The message is required."),
});


const EditClaim = (props: any) => {
    const { t } = useTranslation();
    const [customerName, setCustomerName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [success1, setSuccess1] = useState(false); // Assuming success and setSuccess are passed as props
    const [isResolved, setIsResolved] = useState(props.claim.resolved);
    const { loading, loading1, success, sendMessageToCustomer, markClaimed } = useClaims(props?.claim.companyCode, props?.claim.companyCode, "");
    useEffect(() => {
        if (success) {
            props.onClose(false)
        }
    }, [success])

    const fetchCustomer = async () => {
        if (props.claim.customerId) {
            try {
                const res = await getCustomerByDocIdAsync(props.claim.customerId);
                setCustomerName(res.result.firstName + " " + res.result.lastName);
                setPhoneNumber(res.result.phone);
                // setAssets(props.claim.attachments);
            } catch (error) {
                console.error("Error fetching customer:", error);
            }
        }
    };
    useEffect(() => {
        fetchCustomer();
        setIsResolved(props.claim.resolved);
    }, [props.claim]);

    const handleAddCustomer = (values: any) => {
        console.log('');
    };
    const handleMarkAsResolved = () => {
        setIsResolved(!isResolved);
        markClaimed(props.claim.id);
    };

    const handleSendMessage = (values: { message: string; }) => {
        // Add your logic for sending a message here
        sendMessageToCustomer(props.claim.id, values.message);
    };

    return (
        <React.Fragment>
            <div className="add-company">
                <MetaTags>
                    <title>Edit Claims</title>
                </MetaTags>
            </div>
            <ReactDrawer
                open={props.open}
                position={"right"}
                onClose={props.onClose}
            >
                <div className="add-customer-form" style={{ padding: 20 }}>
                    <Formik
                        initialValues={{
                            message: "",
                            resolved: false,
                        }}
                        validationSchema={CustomerSchema}
                        enableReinitialize={true}
                        onSubmit={(values, actions) => {
                            handleAddCustomer(values);
                            actions.resetForm({
                                values: {
                                    message: "",
                                    resolved: false,
                                },
                            });
                        }}
                    >
                        {({ errors, touched, setFieldValue, values }) => (
                            <Form>
                                <Row>
                                    <div className="mb-3 w-100">
                                        <FormGroup>
                                            <Label for="assets">
                                                {t("claims.assets")}
                                            </Label>
                                            <div>
                                                {props.claim.attachments && props.claim.attachments.length > 0 ? (
                                                    props.claim.attachments.map((asset: any, index: number) => (
                                                        asset.type === 'image' ? (
                                                            <img
                                                                key={index}
                                                                className="asset-item"
                                                                src={asset.file}
                                                                alt={asset.type}
                                                                style={{ width: '200px', height: '150px' }}
                                                            />
                                                        ) : (
                                                            <video key={index} className="asset-item" src={asset.file} controls style={{ width: '200px', height: '150px' }} />
                                                        )
                                                    ))
                                                ) : (
                                                    <p>There is no assets</p>
                                                )}
                                            </div>
                                        </FormGroup>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="mb-3 w-100">
                                        <FormGroup>
                                            <Label for="notes">
                                                {t("claims.notes")}
                                            </Label>
                                            <Field
                                                name="notes"
                                                render={({ field }: any) => (
                                                    <Input {...field} type="text" value={props.claim.notes} disabled />
                                                )}
                                            />
                                        </FormGroup>
                                    </div>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <div className="mb-3">
                                            <FormGroup>
                                                <Label for="customerName">
                                                    {t("claims.customerName")}
                                                </Label>
                                                <Field
                                                    name="customerName"
                                                    render={({ field }: any) => (
                                                        <Input {...field} type="text" value={customerName} disabled />
                                                    )}
                                                />
                                            </FormGroup>
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="mb-3">
                                            <FormGroup>
                                                <Label for="customerNumber">{t("claims.customerNumber")}</Label>
                                                <Field
                                                    name="customerNumber"
                                                    render={({ field }: any) => (
                                                        <Input {...field} type="text" value={phoneNumber} disabled />
                                                    )}
                                                />
                                            </FormGroup>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <div className="mb-3 w-full">
                                        <FormGroup>
                                            <Label for="message">Message to customer</Label>
                                            <Field
                                                name="message"
                                                render={({ field }: any) => (
                                                    <textarea className="w-100" {...field} type="message" />
                                                )}
                                            />
                                        </FormGroup>
                                    </div>
                                </Row>
                                <Row>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="d-flex justify-content-end text-end">
                                            <button
                                                type="submit"
                                                className="btn btn-primary save-user me-3"
                                                disabled={loading1}
                                                onClick={() => handleMarkAsResolved()}

                                            >
                                                {loading1 ? (
                                                    <SpinnerCircular
                                                        size={30}
                                                        thickness={150}
                                                        speed={150}
                                                        color="#36ad47"
                                                        secondaryColor="rgba(0, 0, 0, 0.44)"
                                                    />
                                                ) : (
                                                    <>{isResolved ? "Mark as unresolved" : "Mark as resolved"}</>
                                                )}
                                            </button>
                                            <button
                                                type="submit"
                                                className="btn btn-primary save-user"
                                                disabled={props.loading}
                                                onClick={() => handleSendMessage(values)}
                                            >
                                                {loading ? (
                                                    <SpinnerCircular
                                                        size={30}
                                                        thickness={150}
                                                        speed={150}
                                                        color="#36ad47"
                                                        secondaryColor="rgba(0, 0, 0, 0.44)"
                                                    />
                                                ) : (
                                                    <>Send Message</>
                                                )}
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </div>
            </ReactDrawer>
        </React.Fragment>
    );
};

export default withRouter(EditClaim);
