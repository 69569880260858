import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link, useParams, useHistory } from "react-router-dom";
import Icon from "@ailibs/feather-react-ts";
import {
  Col,
  Row,
  Label,
  Input,
  Card,
  CardTitle,
  Spinner,
  FormGroup,
  Container,
  CardBody,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import ReactDrawer from "react-drawer";
import { useTranslation } from "react-i18next";
import { SpinnerCircular } from "spinners-react";
import {
  getProductByDocIdAsync
} from "src/helpers/backend_helper";
import {
  getAllSitesByCompanyCode,
  getSiteBySiteCode,
  getProductByDocId,
} from "../../store/actions";
import { getAllDealerProductsAsync } from "src/helpers/backend_helper";
import { updateProduct } from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import Dropzone from "react-dropzone";
import { formatBytes } from "../../helpers/general";
import { storage } from "../../helpers/firebase_config";
import Select from "react-select";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

const ProductSchema = Yup.object().shape({
  video: Yup.string().required("The video URL is required."),
  description: Yup.string().required("The decription is required."),
  image: Yup.string().required("The product image is required."),
  terms: Yup.string().required("The product terms are required."),
});

const EditProduct = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { docid } = useParams<any>();
  const history = useHistory();
  const { singleProduct, products, DealerProductId, siteName, siteCode, wasDealerId } = useSelector((state: any) => state.product);
  const { currentUser } = useSelector((state: any) => state.login);
  const [siteList, setSitesList] = useState<any>([]);
  // const { sites, loading } = useSites(currentUser?.code);
  const { sites, loading, authSite, success } = useSelector(
    (state: any) => state.site
  );
  const [termsUploading, setTermsUploading] = useState<any>(false);
  const [imageUploading, setImageUploading] = useState<any>(false);
  const [videoUploading, setVideoUploading] = useState<any>(false);
  // const [siteName, setSiteName] = useState<string>("");
  const [wasInfo, setWasInfo] = useState<any>({
    ProductName: "",
    DealerProductId: "",
    PricingStructureId: "",
    DealerCost: "",
    MonthlyDuration: "",
    AgeFrom: "",
    AgeTo: "",
    MileageFrom: "",
    MileageTo: "",
    ClaimLimit: "",
    AggregatedClaimLimit: "",
  });
  const [hendyInfo, setHendyInfo] = useState<any>({
    siteCode,
    siteName,
    video: "",
    image: "",
    terms: "",
    description: "",
  });

  const { loading_product } = useSelector((state: any) => state.product);
  useEffect(() => {
    // dispatch(getProductByDocId(docid));
    getWasProductById()
  }, []);

  const getWasProductById = async () => {
    const result = await getProductByDocIdAsync(docid);
    if (result.success) {
      if (result.result?.hendyInfo) {
        setHendyInfo({
          siteCode: result.result?.hendyInfo.siteCode,
          video: result.result?.hendyInfo.video,
          image: result.result?.hendyInfo.image,
          terms: result.result?.hendyInfo.terms,
          description: result.result?.hendyInfo.description,
        })
        const product = products.find((item: any) =>
          item.DealerProductId == DealerProductId
          && item.AggregatedClaimLimit == 0
        );
        setWasInfo({ ...(product || {}) })
      } else {
        if (products.length > 0) {
          let filterResult = products.filter((item: any) =>
            item.DealerProductId == DealerProductId
            && item.AggregatedClaimLimit == 0
          );
          if (filterResult.length > 0) {
            setWasInfo({ ...filterResult[0] });
          }
        }
      }
    }
  }

  useEffect(() => {
    if (currentUser?.LOGIN_METHOD === "COMPANY_LOGIN") {
      dispatch(getAllSitesByCompanyCode(currentUser.code));
    }
    if (currentUser?.LOGIN_METHOD === "ADMIN_LOGIN") {
      dispatch(getSiteBySiteCode(currentUser.siteCode));
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser?.LOGIN_METHOD === "ADMIN_LOGIN") {
      siteCodeLists(Array(authSite));
    } else {
      siteCodeLists(sites);
    }
  }, [sites, authSite, singleProduct]);

  const siteCodeLists = async (lists: any) => {
    let arr: { id: string; value: string; label: string }[] = [];
    if (lists.length > 0) {
      lists.map((item: any) => {
        arr.push({ id: item.code, value: item.name, label: item.name });
      });
    }
    if (singleProduct.siteCode) {
      // const result = lists.filter(
      //   (item: any) => item.code == singleProduct.siteCode
      // );
      // if (result.length > 0) {
      //   setSiteName(result[0].name);
      //   const resultData = await getAllDealerProductsAsync({
      //     DealerId: result[0].dealerId,
      //   });
      //   if (resultData?.success) {
      //     if (resultData?.result.length > 0) {
      //       setDealerWasProductList([...resultData.result]);
      //     }
      //   }
      // }
    }
    setSitesList(arr);
  };
  const handleAcceptedTermsFiles = async (setFieldValue: any, files: any) => {
    setTermsUploading(true);
    const path = `/terms/${files[0].name}`;
    const ref = storage.ref(path);
    await ref.put(files[0]);
    const url = await ref.getDownloadURL();
    setTermsUploading(false);
    setFieldValue("terms", url);
  };

  const handleAcceptedVideoFiles = async (setFieldValue: any, files: any) => {
    setVideoUploading(true);
    const path = `/videos/${files[0].name}`;
    const ref = storage.ref(path);
    await ref.put(files[0]);
    const url = await ref.getDownloadURL();
    setVideoUploading(false);
    setFieldValue("video", url);
  };

  const handleAcceptedImageFiles = async (setFieldValue: any, files: any) => {
    setImageUploading(true);
    const path = `/products/${files[0].name}`;
    const ref = storage.ref(path);
    await ref.put(files[0]);
    const url = await ref.getDownloadURL();
    setImageUploading(false);
    setFieldValue("image", url);
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Edit Product</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <Row>
                    <div className="col-sm order-2 order-sm-1">
                      <div className="d-flex align-items-start mt-3 mt-sm-0">
                        <div style={{ paddingRight: 10 }}>
                          <Link to="/products" className="has-arrow">
                            <Icon name="arrow-left" />
                          </Link>
                        </div>
                        <div className="flex-grow-1">
                          <div>
                            <h5 className="font-size-16 mb-1">
                              Title: Product Detail
                            </h5>
                            <p className="text-muted font-size-13">
                              Description: ProductId: {singleProduct?.id}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={3} className="pb-2">
              <p className="pb-2 m-0 text-bold">Product Name</p>
              <Input name="ProductName" defaultValue={wasInfo.ProductName} type="text" disabled />
            </Col>
            <Col md={3} className="pb-2">
              <p className="pb-2 m-0 text-bold">Dealer Cost</p>
              <Input name="DealerCost" defaultValue={wasInfo.DealerCost} type="text" disabled />
            </Col>
            <Col md={3} className="pb-2">
              <p className="pb-2 m-0 text-bold">MonthlyDuration</p>
              <Input name="MonthlyDuration" defaultValue={wasInfo.MonthlyDuration} type="text" disabled />
            </Col>
            <Col md={3} className="pb-2">
              <p className="pb-2 m-0 text-bold">AgeFrom</p>
              <Input name="AgeFrom" defaultValue={wasInfo.AgeFrom} type="text" disabled />
            </Col>
          </Row>
          <Row>
            <Col md={3} className="pb-2">
              <p className="pb-2 m-0 text-bold">AgeTo</p>
              <Input name="AgeTo" defaultValue={wasInfo.AgeTo} type="text" disabled />
            </Col>
            <Col md={3} className="pb-2">
              <p className="pb-2 m-0 text-bold">MileageFrom</p>
              <Input name="MileageFrom" defaultValue={wasInfo.MileageFrom} type="text" disabled />
            </Col>
            <Col md={3} className="pb-2">
              <p className="pb-2 m-0 text-bold">MileageTo</p>
              <Input name="MileageTo" defaultValue={wasInfo.MileageTo} type="text" disabled />
            </Col>
            <Col md={3} className="pb-2">
              <p className="pb-2 m-0 text-bold">ClaimLimit</p>
              <Input name="ClaimLimit" defaultValue={wasInfo.ClaimLimit} type="text" disabled />
            </Col>
            <Col md={3} className="pb-2">
              <p className="pb-2 m-0 text-bold">AggregatedClaimLimit</p>
              <Input name="AggregatedClaimLimit" defaultValue={wasInfo.AggregatedClaimLimit} type="text" disabled />
            </Col>
          </Row>
          <Formik
            initialValues={{
              video: hendyInfo?.video || "",
              siteCode: siteCode || "",
              description: hendyInfo?.description || "",
              image: hendyInfo?.image || "",
              terms: hendyInfo?.terms || "",
            }}
            validationSchema={ProductSchema}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
              let data = {
                wasInfo: wasInfo,
                hendyInfo: {
                  siteCode: siteCode,
                  video: values.video,
                  image: values.image,
                  terms: values.terms,
                  description: values.description,
                },
                wasDealerId: wasDealerId
              }
              dispatch(updateProduct(docid, data, history));
              props.onDrawerClose(false);
            }}
          >
            {({ errors, touched, setFieldValue, values }) => (
              <Form>
                <Row>
                  <Col md="12">
                    <div className="mb-3">
                      <FormGroup>
                        <Label for="description">Description</Label>
                        <Field
                          name="description"
                          render={({ field }: any) => (
                            <textarea
                              {...field}
                              className="form-control"
                              rows={2}
                            />
                          )}
                        />
                        {errors.description && touched.description ? (
                          <div className="error-message">
                            {errors.description}
                          </div>
                        ) : null}
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <CardTitle>Product Image</CardTitle>
                    <Dropzone
                      onDrop={acceptedFiles => {
                        handleAcceptedImageFiles(setFieldValue, acceptedFiles);
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick mt-2"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div>
                            <h6>Drop files here or click to upload.</h6>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews" id="file-previews">
                      {imageUploading && (
                        <div className="d-flex justify-content-center">
                          <i
                            className="bx bx-loader bx-spin align-middle me-2"
                            style={{ fontSize: 46 }}
                          ></i>
                        </div>
                      )}
                      <div className="py-3">
                        <FormGroup>
                          <Label for="image">Or please enter image URL</Label>
                          <Field
                            name="image"
                            render={({ field }: any) => (
                              <Input {...field} type="text" />
                            )}
                          />
                          {errors.image && touched.image ? (
                            <div className="error-message">{errors.image}</div>
                          ) : null}
                        </FormGroup>
                      </div>
                    </div>
                  </Col>
                  <Col md="6">
                    <CardTitle>Video</CardTitle>
                    <Dropzone
                      onDrop={acceptedFiles => {
                        handleAcceptedVideoFiles(setFieldValue, acceptedFiles);
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick mt-2"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div>
                            <h6>Drop files here or click to upload.</h6>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews" id="file-previews">
                      {videoUploading && (
                        <div className="d-flex justify-content-center">
                          <i
                            className="bx bx-loader bx-spin align-middle me-2"
                            style={{ fontSize: 46 }}
                          ></i>
                        </div>
                      )}
                      <div className="py-3">
                        <FormGroup>
                          <Label for="video">Or please enter video URL</Label>
                          <Field
                            name="video"
                            render={({ field }: any) => (
                              <Input {...field} type="text" />
                            )}
                          />
                          {errors.video && touched.video ? (
                            <div className="error-message">{errors.video}</div>
                          ) : null}
                        </FormGroup>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <CardTitle>Terms PDF</CardTitle>
                    <Dropzone
                      onDrop={acceptedFiles => {
                        handleAcceptedTermsFiles(setFieldValue, acceptedFiles);
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick mt-2"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div>
                            <h6>Drop files here or click to upload.</h6>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews" id="file-previews">
                      {termsUploading && (
                        <div className="d-flex justify-content-center">
                          <i
                            className="bx bx-loader bx-spin align-middle me-2"
                            style={{ fontSize: 46 }}
                          ></i>
                        </div>
                      )}
                      <div className="py-3">
                        <FormGroup>
                          <Label for="terms">Terms URL</Label>
                          <Field
                            name="terms"
                            render={({ field }: any) => (
                              <Input {...field} type="text" />
                            )}
                          />
                          {errors.terms && touched.terms ? (
                            <div className="error-message">{errors.terms}</div>
                          ) : null}
                        </FormGroup>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end pb-3">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                        disabled={loading}
                      >
                        {loading ? (
                          <SpinnerCircular
                            size={30}
                            thickness={150}
                            speed={150}
                            color="#36ad47"
                            secondaryColor="rgba(0, 0, 0, 0.44)"
                          />
                        ) : (
                          <>{t("customers.Update")}</>
                        )}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(EditProduct);
