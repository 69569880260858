export enum CustomerTypes {
  GET_CUSTOMERS_BY_SITECODE = "GET_CUSTOMERS_BY_SITECODE",
  GET_CUSTOMERS_BY_SITECODE_SUCCESS = 'GET_CUSTOMERS_BY_SITECODE_SUCCESS',
  GET_CUSTOMERS_BY_SITECODE_FAIL = 'GET_CUSTOMERS_BY_SITECODE_FAIL',

  GET_CUSTOMERS_BY_COMPANYCODE = 'GET_CUSTOMERS_BY_COMPANYCODE',
  GET_CUSTOMERS_BY_COMPANYCODE_SUCCESS = 'GET_CUSTOMERS_BY_COMPANYCODE_SUCCESS',
  GET_CUSTOMERS_BY_COMPANYCODE_FAIL = 'GET_CUSTOMERS_BY_COMPANYCODE_FAIL',

  GET_CUSTOMERS_AND_VEHICLES_BY_COMPANYCODE = 'GET_CUSTOMERS_AND_VEHICLES_BY_COMPANYCODE',
  GET_CUSTOMERS_AND_VEHICLES_BY_COMPANYCODE_SUCCESS = 'GET_CUSTOMERS_AND_VEHICLES_BY_COMPANYCODE_SUCCESS',
  GET_CUSTOMERS_AND_VEHICLES_BY_COMPANYCODE_FAIL = 'GET_CUSTOMERS_AND_VEHICLES_BY_COMPANYCODE_FAIL',

  ADD_CUSTOMER = 'ADD_CUSTOMER',
  ADD_CUSTOMER_SUCCESS = 'ADD_CUSTOMER_SUCCESS',
  ADD_CUSTOMER_FAIL = 'ADD_CUSTOMER_FAIL',

  GET_CUSTOMER_BY_DOCID = 'GET_CUSTOMER_BY_DOCID',
  GET_CUSTOMER_BY_DOCID_SUCCESS = 'GET_CUSTOMER_BY_DOCID_SUCCESS',
  GET_CUSTOMER_BY_DOCID_FAIL = 'GET_CUSTOMER_BY_DOCID_FAIL',

  UPDATE_CUSTOMER_BY_DOCID = 'UPDATE_CUSTOMER_BY_DOCID',
  UPDATE_CUSTOMER_BY_DOCID_SUCCESS = 'UPDATE_CUSTOMER_BY_DOCID_SUCCESS',
  UPDATE_CUSTOMER_BY_DOCID_FAIL = 'UPDATE_CUSTOMER_BY_DOCID_FAIL',

  DELETE_CUSTOMER = 'DELETE_CUSTOMER',
  DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS',
  DELETE_CUSTOMER_FAIL = 'DELETE_CUSTOMER_FAIL'
}
