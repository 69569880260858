import { Redirect } from "react-router-dom";

//Dashboard
// import Dashboard from "../pages/Dashboard";
import Dashboard from "../pages/Dashboard2";
import Company from "../pages/Company";
import EditCompany from "../pages/Company/edit-company";

import Sites from "../pages/Sites";
import EditSite from "../pages/Sites/edit-site";

import Claims from "../pages/ClaimView/ClaimView";
import EditClaim from "../pages/ClaimView/EditClaim";

import Admins from "../pages/Admins";
import EditAdmin from "../pages/Admins/edit-admin";
import AdminScores from "../pages/Admins/admin-score";

import Customers from "../pages/Customers";
import EditCustomer from "../pages/Customers/edit-customer";

import Vehicles from "../pages/Vehicles";
import EditVehicle from "../pages/Vehicles/edit-vehicle";

import Bookings from "../pages/Bookings";
import EditBooking from "../pages/Bookings/edit-booking";

import Products from "../pages/Products";
import ProductDashboard from "../pages/Products/dashboard";
import ProductNotification from "../pages/Products/AddNotification";
import ProductSetting from "../pages/Products/ProductSetting";

import SaleOfProducts from "src/pages/SaleOfProducts";

import EditProduct from "../pages/Products/edit-product";
import BuyProduct from "src/pages/Products/BuyProduct";

import GeoFence from "../pages/GeoFence";
import EditGeoFence from "../pages/GeoFence/edit-geo-fence";
import GeoFenceDisplay from "../pages/GeoFence/display-geo-fence";

import Warranty from "../pages/Warranty";
import EditWarranty from "../pages/Warranty/edit-warranty";

import Checkins from "../pages/Checkins";
import CheckinSettings from "../pages/Checkins/checkin-settings";

import Comms from "../pages/Comms";
import BookingTool from "../pages/BookingTool";

import keylocker from "../pages/Keylocker";
import Notifications from "../pages/Notifications";
import AddNotification from "src/pages/Notifications/AddNotification";
import Templates from "src/pages/Notifications/Templates";
import AddTemplate from "src/pages/Notifications/AddTemplate";
import EditTemplate from "src/pages/Notifications/EditTemplate";

import Groups from "src/pages/Groups";
import EditGroup from "src/pages/Groups/edit-group";

import Onboarding from "src/pages/Settings/Onboarding/Onboarding";
import AddOnboarding from "src/pages/Settings/Onboarding/AddOnboarding";
import EditOnboarding from "src/pages/Settings/Onboarding/EditOnboarding";

// testing....
import Directory from "../pages/Directory2";
import Roles from "../pages/Roles";
import EditRole from "../pages/Roles/edit-role";

//Authentication pages
import { default as AULogin } from "src/pages/Authentication/au/Login";
import { default as HendyLogin } from "src/pages/Authentication/hendy/Login";
import { default as GroupLogin } from "src/pages/Authentication/group1/Login";
import { default as JCGLogin } from "src/pages/Authentication/jcg/Login";
import { default as NavigatorLogin } from "src/pages/Authentication/navigator/Login";
import { default as ComplianceLogin } from "src/pages/Authentication/compliance/Login";
import { default as RRGLogin } from "src/pages/Authentication/rrg/Login";
import { default as CarCenterLogin } from "src/pages/Authentication/car_center/Login";
import { default as WilsonsLogin } from "src/pages/Authentication/wilsons/Login";
import { default as CarzLogin } from "src/pages/Authentication/carz2/Login";
import { default as LookersLogin } from "src/pages/Authentication/lookers/Login";

import Logout from "src/pages/Authentication/Logout";
import Register from "src/pages/Authentication/Register";
import userProfile from "src/pages/Authentication/user-profile";
import HendyStaffLogin from "src/pages/Authentication/hendy/HendyStaffLogin";
import MakeList from "src/pages/Settings/Vehicle/MakeList";
import Unauthorized from "src/pages/Authentication/Unauthorized";
import { Permissions } from "src/enum/Permission";
import Logins from "src/pages/Authentication/Logins";

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
  permission?: Permissions
}

const userRoutes: Array<RouteProps> = [
  //User Profile
  { path: "/:companyCode/profile", component: userProfile },

  //dashboard
  { path: "/dashboard", component: Dashboard },
  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },

  { path: "/companies", permission:Permissions.COMPANIES, component: Company },
  { path: "/companies/:docid", permission:Permissions.COMPANIES, component: EditCompany },

  { path: "/sites", permission:Permissions.SITES, component: Sites },
  { path: "/sites/:docid", permission:Permissions.SITES, component: EditSite },

  { path: "/claims", permission:Permissions.CLAIMS, component: Claims },

  { path: "/admins", permission:Permissions.ADMINS, component: Admins },
  { path: "/admins/:docid", permission:Permissions.ADMINS, component: EditAdmin },
  { path: "/admins/scores/all", permission:Permissions.ADMINS, component: AdminScores },

  { path: "/customers", permission:Permissions.CUSTOMERS, component: Customers },
  { path: "/customers/:docid", permission:Permissions.CUSTOMERS, component: EditCustomer },

  { path: "/vehicles", permission:Permissions.VEHICLES, component: Vehicles },
  { path: "/vehicles/:docid", permission:Permissions.VEHICLES, component: EditVehicle },

  { path: "/bookings", permission:Permissions.BOOKINGS, component: Bookings },
  { path: "/bookings/:docid", permission:Permissions.BOOKINGS, component: EditBooking },

  { path: "/products", permission:Permissions.PRODUCTS, component: Products },
  { path: "/products/dashboard", permission:Permissions.PRODUCTS, component: ProductDashboard },
  { path: "/products/settings", permission:Permissions.PRODUCTS, component: ProductSetting },
  { path: "/products/dashboard/add-notification", permission:Permissions.PRODUCTS, component: ProductNotification },
  { path: "/products/:docid", permission:Permissions.PRODUCTS, component: EditProduct },
  { path: "/products/buy-product/:docid", permission:Permissions.PRODUCTS, component: BuyProduct },

  { path: "/sale-of-products", permission:Permissions.SALE_OF_PRODUCTS, component: SaleOfProducts },

  { path: "/geo-fence", permission:Permissions.GEOFENCE, component: GeoFence },
  { path: "/geo-fence/display", permission:Permissions.GEOFENCE, component: GeoFenceDisplay },
  { path: "/geo-fence/:docid", permission:Permissions.GEOFENCE, component: EditGeoFence },

  { path: "/warranty", permission:Permissions.WARRANTY, component: Warranty },
  { path: "/warranty/:docid", permission:Permissions.WARRANTY, component: EditWarranty },

  { path: "/checkins", permission:Permissions.CHECKINS, component: Checkins },
  { path: "/checkins/settings/all", permission:Permissions.CHECKIN_SETTINGS, component: CheckinSettings },

  { path: "/comms", permission:Permissions.COMMS, component: Comms },
  { path: "/booking-tool", permission:Permissions.BOOKING_TOOL, component: BookingTool },
  { path: "/directories", permission:Permissions.COMPANIES, component: Directory }, //no 'directories' permission, use 'companies'

  { path: "/notifications", permission:Permissions.NOTIFICATIONS, component: Notifications },
  { path: "/notifications/add", permission:Permissions.NOTIFICATIONS, component: AddNotification },
  { path: "/notifications/templates", permission:Permissions.NOTIFICATIONS, component: Templates },
  { path: "/notifications/templates/add/:docid", permission:Permissions.NOTIFICATIONS, component: AddTemplate },
  { path: "/notifications/templates/add", permission:Permissions.NOTIFICATIONS, component: AddTemplate },

  { path: "/Keylocker", permission:Permissions.KEYLOCKER, component: keylocker },

  { path: "/roles", permission:Permissions.SITES, component: Roles }, //no 'Roles' permission, use 'companies'
  { path: "/roles/:docid",  permission:Permissions.SITES, component: EditRole },

  { path: "/groups",  permission:Permissions.SITES, component: Groups }, //no 'Groups' permission, use 'companies'
  { path: "/groups/:docid", permission:Permissions.SITES, component: EditGroup },

  { path: "/settings/onboarding", permission:Permissions.SITES, component: Onboarding }, //no 'Settings' permission, use 'companies'
  { path: "/settings/onboarding/add", permission:Permissions.SITES, component: AddOnboarding },
  { path: "/settings/onboarding/edit/:index", permission:Permissions.SITES, component: EditOnboarding },
  { path: "/settings/makes", permission:Permissions.SITES, component: MakeList },
];

const authRoutes: Array<RouteProps> = [
  //Authentication pages
  { path: "/", exact: true, component: () => <Redirect to="/hendy/login" /> },
  {
    path: "/login",
    exact: true,
    component: () => <Redirect to="/hendy/login" />,
  },
  { path: "/au/login", component: AULogin },
  { path: "/hendy/login", component: HendyLogin },
  { path: "/group1/login", component: GroupLogin },
  { path: "/jcg/login", component: JCGLogin },
  { path: "/navigator/login", component: NavigatorLogin },
  { path: "/compliance_guys/login", component: ComplianceLogin },
  { path: "/rrg/login", component: RRGLogin },
  { path: "/car_center/login", component: CarCenterLogin },
  { path: "/wilsons/login", component: WilsonsLogin },
  { path: "/carz/login", component: CarzLogin },
  { path: "/lookers/login", component: LookersLogin },
  { path: "/logins/:code", component: Logins},

  { path: "/:companyCode/logout", component: Logout },
  { path: "/register", component: Register },
  { path: "/hendy-staff/login", component: HendyStaffLogin },
  { path: "/unauthorised", component: Unauthorized }
];

export { userRoutes, authRoutes };
