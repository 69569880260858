import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MetaTags from "react-meta-tags";
import Icon from "@ailibs/feather-react-ts";
import { ThemeColors } from "src/constants/colors";
import { useTranslation } from "react-i18next";
import styled, { ThemeConsumer } from "styled-components";
import moment from "moment";
import Select from "react-select";
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Slider,
} from "@mui/material";
import useAdmins from "src/hooks/useAdmins";
import useSites from "src/hooks/useSites";
import { IAdmin, ISite } from "src/interfaces/general";
import useProducts from "src/hooks/useProducts";
import { IProduct } from "src/hooks/useProducts";
import useSaleOfProducts from "src/hooks/useSaleOfProducts";
import useRoles from "src/hooks/useRoles";
import { IRole } from "src/interfaces/general";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import ProductSelect2 from "src/components/Common/ProductSelect2";
import RoleSelect2 from "src/components/Common/RoleSelect2";
import SaleOfProductsTable from "./saleOfProductsTable";
import { object } from "yup";

interface ISiteItem {
  label: string;
  id: string;
}

interface ISiteSelectedOption {
  value: ISite | null;
  label: string;
}

interface IProductSelectedOption {
  value: IProduct;
  label: string;
}

interface IUsernameSelectedOption {
  value: IAdmin;
  label: string;
}

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

const pricetext = (price: number) => {
  return `£${price}`;
};

const states = [
  { value: undefined, label: "All States" },
  { value: "Quote", label: "Awaiting Completion" },
  { value: "Live", label: "Live" },
  { value: "Expired", label: "Expired" },
  { value: "Deleted", label: "Deleted" },
  { value: "Cancelled", label: "Cancelled" },
  { value: "Void", label: "Void" },
  { value: "Cancellation Requested", label: "Cancellation Requested" },
  { value: "Financial Change Request", label: "Financial Change Request" },
  { value: "Data Change Request", label: "Data Change Request" },
  { value: "Suspended", label: "Suspended" },
];



const SaleOfProducts = () => {
  const user = useSelector((state: any) => state.login.currentUser);
  const { saleOfProducts, loading } = useSaleOfProducts();
  const { sites } = useSites(user?.code || user?.companyCode);
  const { admins } = useAdmins(user?.code || user?.companyCode);
  const { products } = useProducts();
  const { roles } = useRoles();

  const [show, setShow] = useState<Boolean>(true);
  const [userData, setUserData] = useState<any>({});

  const [dateRange, setDateRange] = useState([
    {
      startDate: moment().subtract(7, "days").toDate(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const handleDateRange = (item: any) => {
    setDateRange([item.selection]);
  };

  const [uniqueProducts, setUniqueProducts] = useState<any[]>([]);
  const [currentSum, setCurrentSum] = useState<number>(280);
  const [currentState, setCurrentState] = useState<any>([]);
  const [currentSite, setCurrentSite] = useState<any>([]);
  const [currentUser, setCurrentUser] = useState<any>([]);
  const [currentProduct, setCurrentProduct] = useState<any>([]);
  const [currentCover, setCurrentCover] = useState<any>([]);
  const [price, setPrice] = useState<number | number[]>([0, 1000]);
  const [currentRole, setCurrentRole] = useState<any>([]);
  const [currentCC, setCurrentCC] = useState<any>([]);
  const [searchPara, setSearchPara] = useState<any>([]);
  const [topSites, setTopSites] = useState<Record<string, number>>();
  const [topUsers, setTopUsers] = useState<Record<string, number>>();

  useEffect(() => {
    setUniqueProducts(products.map(o => o.wasInfo.ProductName).filter((name, index, self) => self.indexOf(name) === index).sort());
  }, [products])

  useEffect(() => {
    const tUsers: Record<string, number> = {};
    const tSites: Record<string, number> = {};

    //YTD start date
    const startDate = moment().startOf("year").toDate().getTime();

    saleOfProducts.map(sale => {
      if (sale.state?.toLowerCase() === "live" && sale.createdAt && sale.createdAt >= startDate) {
        if (sale.admin?.userName) {
          tUsers[sale.admin?.userName] = (tUsers[sale.admin?.userName] ?? 0) + sale.price;
        }
        if (sale.site) {
          tSites[sale.site] = (tUsers[sale.site] ?? 0) + sale.price;
        }
      }
    })

    //order by descending, and take top 10
    const sortedTUsers = Object.entries(tUsers).sort((a, b) => b[1] - a[1]).slice(0, 5);
    const sortedTSites = Object.entries(tSites).sort((a, b) => b[1] - a[1]).slice(0, 5);
    setTopUsers(Object.fromEntries(sortedTUsers));
    setTopSites(Object.fromEntries(sortedTSites));

  }, [saleOfProducts]);

  useEffect(() => {

  }, [])

  const resetFilter = () => {
    setCurrentState('');
    setCurrentSite('');
    setCurrentUser('');
    setCurrentProduct('');
    setCurrentCover('');
    setCurrentRole('');
    setCurrentCC('');

    setDateRange([{
      startDate: moment().subtract(7, "days").toDate(),
      endDate: new Date(),
      key: "selection",
    }])

    var params = getSearchParams();
    params.startDate = moment().subtract(7, "days").toDate().getTime();
    params.endDate = new Date().getTime();
    params.state = '';
    params.site = '';
    params.userName = '';
    params.role = '';
    params.product = '';
    params.cover = '';
    params.cc = '';

    setSearchPara(params);

  }

  const getSearchParams = () => {
    return {
      startDate: dateRange[0].startDate.getTime(),
      endDate: dateRange[0].endDate.getTime(),
      state: currentState.value,
      site: currentSite.value?.name,
      userName: currentUser.value?.userName,
      product: currentProduct.value,
      cover: currentCover?.value,
      price: price,
      role: currentRole.value?.name,
      cc: currentCC?.value,
    }
  }

  const handleCallback = (sum: number) => {
    setCurrentSum(sum);
  };

  const handleStateChange = (selectedOption: any) => {
    setCurrentState(selectedOption);
  };

  const handleSiteChange = (selectedOption: ISiteSelectedOption) => {
    setCurrentSite(selectedOption);
  };

  const handleUserChange = (selectedOption: IUsernameSelectedOption) => {
    setCurrentUser(selectedOption);
  };

  const handleProductChange = (selectedOption: IProductSelectedOption) => {
    setCurrentProduct(selectedOption);
  };

  const handleCoverChange = (selectedOption: any) => {
    setCurrentCover(selectedOption);
  };

  const handlePriceChange = (event: Event, newPrice: number | number[]) => {
    setPrice(newPrice);
  };

  const handleCCChange = (selectedOption: any) => {
    setCurrentCC(selectedOption);
  };

  const handleRoleChange = (selectedOption: any) => {
    setCurrentRole(selectedOption);
  };

  const handleSearch = () => {
    console.log("SEARCH", getSearchParams())
    setSearchPara(getSearchParams);
  };

  const setShortcut = (option: string) => {

    var now = new Date();
    var tempRange = {
      startDate: moment().subtract(7, "days").toDate(),
      endDate: now,
      key: "selection",
    }

    switch (option) {
      case "day":
        tempRange.startDate = now
        break;

      case "week":
        tempRange.startDate = moment().startOf("week").toDate()
        break;

      case "month":
        tempRange.startDate = moment().startOf("month").toDate()
        break;

      case "year":
        tempRange.startDate = moment().startOf("year").toDate()
        break;
    }

    setDateRange([tempRange]);
    var searchParams = getSearchParams();
    searchParams.startDate = tempRange.startDate.getTime();
    searchParams.endDate = tempRange.endDate.getTime();
    setSearchPara(searchParams);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Sale of Products</title>
        </MetaTags>
        <ApplyBox>
          <PageTitleRow>
            <RightBox>
              <PageTitle>Sale of Products</PageTitle>
              <IconButton>
                <Icon
                  name="filter"
                  color={ThemeColors.green}
                  size={30}
                  onClick={() => setShow(!show)}
                />
              </IconButton>
            </RightBox>
            {/*             <LeftBox>
                  <LeftBoxTitle>Total Revenue</LeftBoxTitle>
                  <LeftValueBox>
                  <LeftValue>£{(currentSum * 0.05).toFixed(2)}</LeftValue> 
                  </LeftValueBox>
            </LeftBox> */}
          </PageTitleRow>
          {show && (
            <Card>
              <FilterContainer>
                <FilterColumn>

                  <FilterHeading>
                    <h4>Date Created</h4>
                  </FilterHeading>

                  <DateRange
                    color={ThemeColors.main}
                    showDateDisplay={false}
                    editableDateInputs={false}
                    onChange={item => handleDateRange(item)}
                    moveRangeOnFirstSelection={false}
                    ranges={dateRange}
                    maxDate={new Date()}
                  />
                  <b>Shortcuts</b>
                  <FilterSubColumnsContainer>

                    <Button type="button" onClick={() => setShortcut("day")} style={{ background: ThemeColors.green }}>
                      Today
                    </Button>
                    <Button type="button" onClick={() => setShortcut("7days")} style={{ background: ThemeColors.green }}>
                      7 Days
                    </Button>
                    <Button type="button" onClick={() => setShortcut("week")} style={{ background: ThemeColors.green }}>
                      Week to Date
                    </Button>
                    <Button type="button" onClick={() => setShortcut("month")} style={{ background: ThemeColors.green }}>
                      Month to Date
                    </Button>
                    <Button type="button" onClick={() => setShortcut("year")} style={{ background: ThemeColors.green }}>
                      Year to date
                    </Button>

                  </FilterSubColumnsContainer>


                </FilterColumn>
                <FilterColumn>
                  <FilterHeading>
                    <h4 >Filters</h4>
                    <Button type="button" onClick={resetFilter}>Reset Filter</Button>
                  </FilterHeading>
                  <FilterSubColumnsContainer>

                    <FilterSubColumn>

                      <FormControl sx={{ width: '100%' }}>
                        <FormLabel
                          id="state-radio-buttons-group-label"
                          sx={{ color: ThemeColors.main, fontWeight: 600 }}
                        >
                          Status
                        </FormLabel>
                        <Select
                          value={
                            currentState.value
                              ? {
                                value: currentState.value,
                                label: currentState.label,
                              }
                              : null
                          }
                          onChange={handleStateChange}
                          options={
                            states.length > 0
                              ? states.map((state: any) => ({
                                value: state.value,
                                label: state.label,
                              }))
                              : [{ value: null, label: "Loading" }]
                          }
                          placeholder="All States"
                        />
                      </FormControl>
                      <FormControl sx={{ width: '100%' }}>
                        <FormLabel
                          id="site-search-label"
                          sx={{ color: ThemeColors.main, fontWeight: 600 }}
                        >
                          Site
                        </FormLabel>
                        <Select
                          value={
                            currentSite.value
                              ? {
                                value: currentSite.value,
                                label: currentSite.value.name,
                              }
                              : null
                          }
                          onChange={handleSiteChange}
                          options={
                            sites.length > 0
                              ? [{ value: null, label: "All Sites" }].concat(
                                sites.map((site: any) => ({
                                  value: site,
                                  label: site.name,
                                }))
                              )
                              : [{ value: null, label: "Loading" }]
                          }
                          placeholder="All Sites"
                        />
                      </FormControl>
                      <FormControl sx={{ width: '100%' }}>
                        <FormLabel
                          id="username-search-label"
                          sx={{ color: ThemeColors.main, fontWeight: 600 }}
                        >
                          User
                        </FormLabel>
                        <Select
                          value={
                            currentUser.value
                              ? {
                                value: currentUser.value,
                                label: currentUser.value.userName,
                              }
                              : null
                          }
                          onChange={handleUserChange}
                          options={
                            admins.length > 0
                              ? [{ value: null, label: "All Users" }].concat(
                                admins.map((admin: any) => ({
                                  value: admin,
                                  label: admin.userName,
                                }))
                              )
                              : [{ value: null, label: "Loading" }]
                          }
                          placeholder="All Users"
                        />
                      </FormControl>
                      <FormControl sx={{ width: '100%' }}>
                        <FormLabel
                          id="product-search-label"
                          sx={{ color: ThemeColors.main, fontWeight: 600 }}
                        >
                          Product
                        </FormLabel>
                        <Select
                          value={
                            currentProduct.value
                              ? {
                                value: currentProduct.value,
                                label: currentProduct.value,
                              }
                              : null
                          }
                          onChange={handleProductChange}
                          options={
                            uniqueProducts.length > 0
                              ? [{ value: null, label: "All Products" }].concat(
                                uniqueProducts.map(o => {
                                  return {
                                    label: o,
                                    value: o
                                  }
                                })
                              )
                              : [{ value: null, label: "Loading" }]
                          }
                          placeholder="All Products"
                        />
                        <FormControl sx={{ width: '100%', marginTop: '10px' }}>
                          <Select
                            value={currentCover}
                            onChange={(selectedOption: any) => {
                              handleCoverChange(selectedOption);
                            }}
                            options={[
                              { value: undefined, label: "All" },
                              { value: "Platinum", label: "Platinum" },
                              { value: "Diamond", label: "Diamond" },
                            ]}
                            isSearchable={true}
                            placeholder="All Covers"
                          />
                        </FormControl>
                      </FormControl>
                      <Button type="submit" onClick={handleSearch}>
                        Apply
                      </Button>
                    </FilterSubColumn>
                  </FilterSubColumnsContainer>
                </FilterColumn>
                <FilterColumn>
                  <FilterHeading>
                    <h4>Top Performers <small>YTD Results</small></h4>

                  </FilterHeading>
                  <FilterSubColumnsContainer>
                    <FilterSubColumn>
                      <FormLabel sx={{ color: ThemeColors.main, fontWeight: 600 }}>Username</FormLabel>

                      {Object.keys(topUsers ?? {}).length > 0 ?
                        Object.keys(topUsers ?? {}).map((key, index) => (
                          <div key={index}> {key}</div>
                        ))
                        : <small><em>Not enough data</em></small>}

                    </FilterSubColumn>
                    <FilterSubColumn>
                      <FormLabel sx={{ color: ThemeColors.main, fontWeight: 600 }}>Site</FormLabel>

                      {Object.keys(topSites ?? {}).length > 0 ?
                        Object.keys(topSites ?? {}).map((key, index) => (
                          <div key={index}> {key}</div>
                        ))
                        : <small><em>Not enough data</em></small>}

                    </FilterSubColumn>
                  </FilterSubColumnsContainer>
                </FilterColumn>
              </FilterContainer>
            </Card>
          )}
        </ApplyBox>
        <Header>

          <ItemList>
            {/*           <UserInfo>
            <Label>{user.siteCode ? "Site Code: " + user.siteCode : "Company Code: " + user.code}</Label>
            <Label>Username: {user.userName}</Label>
          </UserInfo> */}
            <UserContainer companyCode={user.code} siteCode={user.siteCode} userName={user.userName}></UserContainer>
            <Item value={userData.quote + userData.live + userData.cancelled + userData.other} title="Total" />
            <Item value={userData.quote} title="Awaiting Completion" />
            <Item value={userData.cancelled} title="Cancelled" />
            <Item value={userData.live} title="Live" />
            <Item value={userData.other} title="Other" />
          </ItemList>
        </Header>
        <SearchResult>
          <SaleOfProductsTable
            data={searchPara}
            callback={handleCallback}
            onChangeValues={setUserData}
          />
        </SearchResult>
      </div>
    </React.Fragment>
  );
};

export default SaleOfProducts;

const ApplyBox = styled.div`
  padding: 10px;
`;

const PageTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const RightBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

const PageTitle = styled.div`
  font-size: 40px;
  font-weight: 600;
  color: ${ThemeColors.main};
  white-space: nowrap;
`;

const LeftBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LeftBoxTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${ThemeColors.main};
  white-space: nowrap;
`;

const LeftValueBox = styled.div`
  padding: 4px;
  background: ${ThemeColors.main};
  border-radius: 4px;
`;

const LeftValue = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: ${ThemeColors.white};
  white-space: nowrap;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: wrap;
  border: solid 1px ${ThemeColors.lightGrey};
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 20px;
`;

const DatePickerBox = styled.div`
  width: 200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${ThemeColors.white};
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding: 8px;
  margin: 3px;
`;

const CustomDatePickerWrapper = styled.div`
  .react-datepicker__input-container input {
    border: none;
    outline: none;
    width: 100px;
    background: ${ThemeColors.white};
    font-size: 16px;
    font-weight: 600;
    color: ${ThemeColors.main};
  }
`;

const Label = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${ThemeColors.main};
  white-space: nowrap;
`;

const SearchResult = styled.div`
  margin-top: -50px;
`;

const IconButton = styled.button`
  border: none;
  background-color: transparent;
`;

const Button = styled.button`
  background: ${ThemeColors.main};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
  padding: 8px;
  font-size: 16px;
  color: ${ThemeColors.white};
  border: none;
`;


const Header = styled.div`
  margin: 10px;
  display: flex;
  background: ${ThemeColors.extraLightGrey};
  border-radius: 4px;
  padding: 20px;
`



const Item = (props: any) => {
  return (
    <ItemContainer>
      <ItemInner>
        <ItemValue>{props.value}</ItemValue>
        <ItemTitle>{props.title}</ItemTitle>
      </ItemInner>
    </ItemContainer>
  )
}

const UserContainer = (props: any) => {
  return (
    <UserInfo>
      <ItemInner>
        <div>Company Code: {props.companyCode} {props.siteCode ? "Site Code: " + props.siteCode : ""}</div>
      </ItemInner>
    </UserInfo>
  )
}

const ItemList = styled.div`
  flex:1;
  display: flex;
  gap: 20px;
  justify-content: space-between;
`

const ItemContainer = styled.div`
  display:flex;
  flex:1;
  border: solid 1px ${ThemeColors.lightGrey};
  justify-content: center;
  align-items: center; 
  border-radius: 4px;
  background: ${ThemeColors.white};
  text-align: center; 
`

const ItemInner = styled.div`
    width: 100%; 
`

const ItemValue = styled.div`
  font-size: 20px;
  font-weight: 800;
  color: ${ThemeColors.main};

`

const ItemTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${ThemeColors.darkGrey};

`

const UserInfo = styled.div`
text-align: left !important; 
display:flex;
flex:1;
justify-content: center;
align-items: center; 
font-weight:600; 
font-size:18px;
min-width:200px;
color: ${ThemeColors.main};
`

const FilterContainer = styled.div`
display:flex;
width:100%;
gap:10px;

& label{
  height:auto;
}

`

const FilterColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  &:not(:first-child) {
    border-left: 1px solid ${ThemeColors.lightGrey};
  }
`;

const FilterSubColumn = styled.div`
flex: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  padding-top:0;
  min-width: 0;
  &:not(:first-child) {
    border-left: 1px solid ${ThemeColors.lightGrey};
  }
`;

const FilterSubColumnsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
`;


const FilterHeading = styled.div`
  
  display:flex;
  flex-direction:row;
  justify-content: space-between;
  margin: 10px 10px 0px 10px;
  & h4 {
    color: ${ThemeColors.main}
    margin-bottom:0;
  }
  & small {
    color: ${ThemeColors.green};
    font-size:0.7em;
  }
  & button {
    font-size:0.8em;
  }
`;
const FilterSubHeading = styled.div`
  color: ${ThemeColors.green}
`;
