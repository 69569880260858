import PropTypes from "prop-types";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import Icon, { IconName } from "@ailibs/feather-react-ts";
import SimpleBar from "simplebar-react";
import { useTranslation, withTranslation } from "react-i18next";
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { MenuList, AdminMenuList } from "./LeftMenu";

export interface ISubMenu {
  name: string;
  permission: string;
  link: string;
}

export interface IMenu {
  icon: IconName;
  title: string;
  link?: string;
  permission: string;

  subMenu?: Array<ISubMenu>;
}

const MenuItem = (props: IMenu) => {
  const { t } = useTranslation();

  const hasSubMenu = props.subMenu && props.subMenu.length > 0;
  const { currentUser } = useSelector((state: any) => state.login);
  const fullPermission = currentUser?.LOGIN_METHOD === 'COMPANY_LOGIN';
  const permissions = currentUser?.permissions || {};

  const hasPermission = (permission: string) => fullPermission || permissions[permission];

  return hasPermission(props.permission) ? (
    <li>
      <Link
        to={props.link ? props.link : "/#"}
        className={hasSubMenu ? "has-arrow" : ""}
      >
        <Icon name={props.icon} />
        <span className="sidebar-title">{t(props.title)}</span>
      </Link>

      {/* Sub menu list */}
      {hasSubMenu && <ul className="sub-menu">
        {props.subMenu?.filter(menu => hasPermission(menu.permission))
          .map((menu, index) => (
            <li key={index}>
              <Link to={menu.link}>{t(menu.name)}</Link>
            </li>
          ))}
      </ul>}
    </li>
  ) : null;
}





const SidebarContent = (props: any) => {
  const ref = useRef<any>();

  const [menuList, setMenuList ] = useState<IMenu[]>();
  const user = useSelector((state: any) => state.login.currentUser);

  useEffect(() => {
    if (user !== null && user.code === "00000") {
      setMenuList(AdminMenuList);
    }else {
      setMenuList(MenuList);
    }
  }, [user])

  const activateParentDropdown = useCallback(item => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul: any = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item: any) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>

            {menuList && menuList.map((menu, index) => <MenuItem {...menu} key={index} />)}

          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(withRouter(SidebarContent));


