
import React, { useState } from 'react';
import MetaTags from 'react-meta-tags';
import { useHistory, useParams } from 'react-router';
import { Button, Col, Container, Input, Label, Row } from 'reactstrap';
import NavHeader from 'src/components/Common/NavHeader';
import useNotifications from 'src/hooks/useNotifications';
import ProductDetailCard from '../Notifications/ProductDetailCard';
import styled from 'styled-components';
import { getWasContractPDF, makeContractLive, receivedDocument, setupWasDirectPayment, updateContractNumber, updateVehicleAsync } from 'src/helpers/backend_helper';
import utils from 'src/helpers/utils';
import { v4 } from 'uuid'
import useVehicles from 'src/hooks/useVehicles';
import SweetAlert from 'react-bootstrap-sweetalert';
import { ThemeColors } from 'src/constants/colors';



const BuyProduct = () => {

    const { docid } = useParams<any>();
    const history = useHistory();
    const { loading: fetching, fetchNotificationById, singleNotification } = useNotifications();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [holderName, setHolderName] = React.useState<string>('');
    const [accountNumber, setAccountNumber] = React.useState<string>('');
    const [sortcode, setSortCode] = React.useState<string>('');
    const [email, setEmail] = React.useState<string>('');
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const { vehicles } = useVehicles(singleNotification?.data?.customerId);

    const validForm = () => {
        if (holderName.length > 0 &&
            accountNumber.length == 8 && accountNumber.match(/^[0-9]+$/) &&
            sortcode.length == 6 && sortcode.match(/^[0-9]+$/) &&
            email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            return true;
        }
    }

    const addDocument = async (body: { id: string, uri: string, type: string, name?: string }) => {
        const currentVehicle = vehicles.find(v => v.regNo === singleNotification?.regNo)
        if (currentVehicle) {
            const documents = currentVehicle.documents || [];
            documents.push(body);
            currentVehicle.documents = [...documents];
            await updateVehicleAsync(currentVehicle.id || "", currentVehicle);
        }
    }

    const onConfirm = async () => {
        if (!validForm()) {
            alert('Please fill all fields with valid data');
            return;
        }

        const data: any = {
            OriginatorName: holderName,
            AccountNumber: accountNumber,
            SortCode: sortcode,
            NotifyEmailAddress: email
        };

        const contractNumber = singleNotification?.data?.contractNumber;

        setLoading(true);
        try {
            const res = await setupWasDirectPayment(contractNumber, data);
            const res2 = await makeContractLive(contractNumber);
            const newContractNumber = JSON.parse(res2.data).ContractNumber;
            const pdf = await getWasContractPDF(newContractNumber);
            const url = await utils.uploadBase64(pdf.data, "products");
            await updateContractNumber({
                oldContractNumber: contractNumber,
                newContractNumber,
                url
            });
            addDocument({
                id: v4(),
                name: newContractNumber,
                uri: url,
                type: "service",
            });
            receivedDocument({
                url,
                customerId: singleNotification?.customerId || "",
                contractNumber: newContractNumber
            });
            setShowSuccessAlert(true);
        } catch (error) {
            alert("Please try again later");
        };
        setLoading(false);
    }

    const onDirect = () => {
        window.open("https://www.directdebit.co.uk/direct-debit-explained/");
    };

    React.useEffect(() => {
        if (docid) {
            fetchNotificationById(docid);
        }
    }, [docid]);

    // @ts-ignore
    const product = singleNotification?.data || null;

    return (
        <React.Fragment>
            <MetaTags>
                <title>Buy Product</title>
            </MetaTags>
            <div
                className="page-content"
                style={{ height: "100vh", overflow: "scroll" }}
            >
                <Container fluid>
                    <NavHeader
                        title="Buy Product"
                        description="You can buy a product here."
                    />
                    <Row>
                        <Col md={6} xl={4}>
                            {product ? <ProductDetailCard {...product} /> : null}
                        </Col>
                        <Col md={6} xl={4}>
                            <RowItem>
                                <Label>Account Holder Name</Label>
                                <Input
                                    type="text"
                                    placeholder='Jon Doe'
                                    value={holderName}
                                    onChange={e => setHolderName(e.target.value)}
                                />
                            </RowItem>
                            <RowItem>
                                <Label>Account Number</Label>
                                <Input
                                    type="text"
                                    placeholder='12345678'
                                    value={accountNumber}
                                    onChange={e => setAccountNumber(e.target.value)}
                                />
                            </RowItem>
                            <RowItem>
                                <Label>Sortcode</Label>
                                <Input
                                    type="text"
                                    placeholder='123456'
                                    value={sortcode}
                                    onChange={e => setSortCode(e.target.value)}
                                />
                            </RowItem>
                            <RowItem>
                                <Label>Email</Label>
                                <Input
                                    type="text"
                                    placeholder='test@ex.com'
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                />
                            </RowItem>
                            <Descrition>
                                Your payments are protected{"\n"}by the <Link onClick={onDirect}>Direct Debit Guarantee</Link>
                            </Descrition>
                        </Col>
                        <ButtonContainer>
                            <Button
                                className="template-create"
                                color="success"
                                onClick={onConfirm}
                                disabled={loading || fetching}
                            >
                                Confirm
                            </Button>
                        </ButtonContainer>
                    </Row>
                    {showSuccessAlert ? (
                        <SweetAlert
                            success
                            title={"Subscribed Successfully"}
                            onConfirm={() => {
                                history.goBack();
                                setShowSuccessAlert(false);
                            }}
                        />
                    ) : null}
                </Container>
            </div>
        </React.Fragment >
    )
}

export default BuyProduct;


const RowItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  align-items: center;
`;

const ButtonContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;

  .template-create {
    margin-right: 10px;
  }
`;

const Descrition = styled.p`
  font-size: 14px;
  margin-top: 20px;
`;

const Link = styled.span`
  color:${ThemeColors.main};
  cursor: pointer;
  text-decoration: underline;
`;