import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import ReactDrawer from "react-drawer";
import { Col, Row } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { SpinnerCircular } from "spinners-react";
import { IRole } from "src/interfaces/general";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AddRole = (props: any) => {
  const [date, setDate] = useState(new Date());
  const dueDate = date.toLocaleDateString();
  const [formData, setFormData] = useState({
    companies: false,
    sites: false,
    admins: false,
    customers: false,
    bookings: false,
    checkins: false,
    notifications: false,
    keylocker: false,
    comms: false,
    checkinSettings: false,
    bookingTool: false,
    products: false,
    vehicles: false,
    quotes: false,
    invocies: false,
    paymentSetting: false,
    paymentHistory: false,
    geofence: false,
    warranty: false,
    saleOfProducts: false,
    claims: false
  });

  const handleAddRole = (values: IRole) => {
    const permissions = {
      companies: formData.companies,
      sites: formData.sites,
      admins: formData.admins,
      customers: formData.customers,
      bookings: formData.bookings,
      checkins: formData.checkins,
      notifications: formData.notifications,
      keylocker: formData.keylocker,
      comms: formData.comms,
      checkinSettings: formData.checkinSettings,
      bookingTool: formData.bookingTool,
      products: formData.products,
      vehicles: formData.vehicles,
      quotes: formData.quotes,
      invocies: formData.invocies,
      paymentSetting: formData.paymentSetting,
      paymentHistory: formData.paymentHistory,
      geofence: formData.geofence,
      saleOfProducts: formData.saleOfProducts,
      claims: formData.claims,
      warranty: false,
    };
    values.permissions = permissions;
    props.addRole(values);
  };

  const handlePermissionCheck = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.checked });
  };

  useEffect(() => {
    if (props.success) {
      props.onDrawerClose(false);
    }
  }, [props.success]);

  return (
    <React.Fragment>
      <div className="add-company">
        <MetaTags>
          <title>Add New Role</title>
        </MetaTags>
      </div>
      <ReactDrawer
        open={props.open}
        position={"right"}
        onClose={props.onDrawerClose}
      >
        <div className="add-company-form" style={{ padding: 20 }}>
          <AvForm
            onValidSubmit={(e: any, values: any) => {
              handleAddRole(values);
            }}
          >
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <AvField
                    name="name"
                    label="Name"
                    type="text"
                    errorMessage="Invalid Name"
                    validate={{
                      required: { value: true },
                    }}
                    value=""
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <AvField
                    name="companyCode"
                    label="Company Code"
                    type="text"
                    errorMessage="Invalid Company Code"
                    validate={{
                      required: { value: true },
                    }}
                    value=""
                  />
                </div>
              </Col>
            </Row>

            <Row className="mt-4 p-3 directory-container">
              <h4>Permissions</h4>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="companies"
                  checked={formData.companies}
                  onChange={e => handlePermissionCheck(e)}
                />
                <label className="form-check-label">Companies</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="sites"
                  checked={formData.sites}
                  onChange={e => handlePermissionCheck(e)}
                />
                <label className="form-check-label">Sites</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="admins"
                  checked={formData.admins}
                  onChange={e => handlePermissionCheck(e)}
                />
                <label className="form-check-label">Admins</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="customers"
                  checked={formData.customers}
                  onChange={e => handlePermissionCheck(e)}
                />
                <label className="form-check-label">Customers</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="bookings"
                  checked={formData.bookings}
                  onChange={e => handlePermissionCheck(e)}
                />
                <label className="form-check-label">Bookings</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="checkins"
                  checked={formData.checkins}
                  onChange={e => handlePermissionCheck(e)}
                />
                <label className="form-check-label">Checkins</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="notifications"
                  checked={formData.notifications}
                  onChange={e => handlePermissionCheck(e)}
                />
                <label className="form-check-label">Notifications</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="keylocker"
                  checked={formData.keylocker}
                  onChange={e => handlePermissionCheck(e)}
                />
                <label className="form-check-label">Keylocker</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="comms"
                  checked={formData.comms}
                  onChange={e => handlePermissionCheck(e)}
                />
                <label className="form-check-label">Comms</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="checkinSettings"
                  checked={formData.checkinSettings}
                  onChange={e => handlePermissionCheck(e)}
                />
                <label className="form-check-label">Checkin Settings</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="bookingTool"
                  checked={formData.bookingTool}
                  onChange={e => handlePermissionCheck(e)}
                />
                <label className="form-check-label">Booking Tool</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="products"
                  checked={formData.products}
                  onChange={e => handlePermissionCheck(e)}
                />
                <label className="form-check-label">Products</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="geofence"
                  checked={formData.geofence}
                  onChange={e => handlePermissionCheck(e)}
                />
                <label className="form-check-label">GeoFence</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="warranty"
                  checked={formData.warranty}
                  onChange={e => handlePermissionCheck(e)}
                />
                <label className="form-check-label">Warranty</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="vehicles"
                  checked={formData.vehicles}
                  onChange={e => handlePermissionCheck(e)}
                />
                <label className="form-check-label">Vehicles</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="quotes"
                  checked={formData.quotes}
                  onChange={e => handlePermissionCheck(e)}
                />
                <label className="form-check-label">Quotes</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="invocies"
                  checked={formData.invocies}
                  onChange={e => handlePermissionCheck(e)}
                />
                <label className="form-check-label">Invoices</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="paymentSetting"
                  checked={formData.paymentSetting}
                  onChange={e => handlePermissionCheck(e)}
                />
                <label className="form-check-label">Payment Setting</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="paymentHistory"
                  checked={formData.paymentHistory}
                  onChange={e => handlePermissionCheck(e)}
                />
                <label className="form-check-label">Payment History</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="saleOfProducts"
                  checked={formData.saleOfProducts}
                  onChange={e => handlePermissionCheck(e)}
                />
                <label className="form-check-label">Sale of Products</label>
              </div>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="submit"
                    className="btn btn-success save-user"
                    disabled={props.loading}
                  >
                    {props.loading ? (
                      <SpinnerCircular
                        size={30}
                        thickness={150}
                        speed={150}
                        color="#36ad47"
                        secondaryColor="rgba(0, 0, 0, 0.44)"
                      />
                    ) : (
                      <>Save</>
                    )}
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </div>
      </ReactDrawer>
    </React.Fragment>
  );
};

export default withRouter(AddRole);
