import { useEffect, useState } from "react";
import { IBooking, IClaim, TGraphData } from "src/interfaces/general";
import { markClaim, sendMessage, fetchClaimsBySiteCode } from "src/helpers/backend_helper";


const useClaims = (companyCode?: string, msg?: string, siteCode?: string) => {
    const [claims, setClaims] = useState<Array<IClaim>>([]);
    const [bookings, setBookings] = useState<Array<IBooking>>([]);
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [success, setSuccess] = useState(false);
    const fetchClaims = async (companyCode: string, siteCode: string) => {
        setLoading(true);
        try {
            const res = await fetchClaimsBySiteCode(companyCode, siteCode);
            if (res.success) {
                setClaims(res.result);
            }
        } catch (error) {
            console.error("Error fetching claims:", error);
        } finally {
            setLoading(false);
        }
    };

    const markClaimed = async (id: string) => {
        setLoading1(true);
        try {
            const res = await markClaim(id);
            if (res.success) {
                const index = claims.findIndex(claim => claim.id === id);
                if (index !== -1) {
                    claims[index].resolved = !claims[index].resolved;
                    setClaims([...claims]);
                }
            }
        } catch (error) {
            console.error("Error marking claim:", error);
        } finally {
            setLoading1(false);
            setSuccess(true);
        }
    };

    const sendMessageToCustomer = async (id: string, msg: string) => {
        setLoading(true);
        try {
            const res = await sendMessage(id, msg);
            if (res.success) {
                const index = claims.findIndex(claim => claim.id === id);
                if (index !== -1) {
                    claims[index].type = "claimed";
                    setClaims([...claims]);
                }
            }
        } catch (error) {
            console.error("Error sending message:", error);
        } finally {
            setLoading(false);
            setSuccess(true);
        }
    };
    useEffect(() => {
        if (companyCode && siteCode) {
            fetchClaims(companyCode, siteCode);
        }
    }, []);
    return {
        bookings,
        loading,
        loading1,
        markClaimed,
        sendMessageToCustomer,
        fetchClaims,
        success,
        claims,
    };
}

export default useClaims;