import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import ReactDrawer from "react-drawer";
import { Col, Row, Label, Input, FormGroup } from "reactstrap";
import { addCustomer } from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import { SpinnerCircular } from "spinners-react";
import { useTranslation } from "react-i18next";
import AddAvatar from "src/components/Common/AddAvatar";
import SiteSelect from "src/components/Common/SiteSelect";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  getAllSitesByCompanyCode,
  getSiteBySiteCode,
} from "../../store/actions";
import Select from "react-select";

const CustomerSchema = Yup.object().shape({
  siteCode: Yup.string().required("The site code is required."),
  firstName: Yup.string().required("The first name is required."),
  lastName: Yup.string().required("The last name is required."),
  phone: Yup.string().required("The phone is required."),
  email: Yup.string().email("Invalid email").required("The email is required."),
  title: Yup.object().required("The title is required."),
  postcode: Yup.string().required("The post code is required."),
  houseName: Yup.string().required("The house name is required."),
  address: Yup.string().required("The address is required."),
});

export const titleOptions = [
  { value: "Mr", label: "Mr" },
  { value: "Mrs", label: "Mrs" },
  { value: "Miss", label: "Miss" },
  { value: "Ms", label: "Ms" },
  { value: "Dr", label: "Dr" },
  { value: "Rev", label: "Rev" },
  { value: "Sir", label: "Sir" },
  { value: "Madam", label: "Madam" },
  { value: "Other", label: "Other" },
];

const AddCustomer = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [avatar, setAvatar] = useState("");
  const [siteList, setSitesList] = useState<any>([]);
  const [siteName, setSiteName] = useState("");
  const { sites, authSite } = useSelector((state: any) => state.site);
  const { success, loading } = useSelector((state: any) => state.customer);
  const { currentUser } = useSelector((state: any) => state.login);
  const [registered, setRegistered] = useState(false);

  useEffect(() => {
    if (currentUser?.LOGIN_METHOD === "COMPANY_LOGIN") {
      dispatch(getAllSitesByCompanyCode(currentUser.code));
    }
    if (currentUser?.LOGIN_METHOD === "ADMIN_LOGIN") {
      //dispatch(getSiteBySiteCode(currentUser.siteCode));
      dispatch(getAllSitesByCompanyCode(currentUser.companyCode));
    }
  }, [currentUser]);

  useEffect(() => {
    //if (currentUser?.LOGIN_METHOD === "ADMIN_LOGIN") {
    //  siteCodeLists(Array(authSite));
    //} else {
    siteCodeLists(sites);
    //}
  }, [sites, authSite]);

  const siteCodeLists = (lists: any) => {
    let arr: { value: any; label: any }[] = [];
    if (lists.length > 0) {
      lists.map((item: any) => {
        arr.push({ value: item.code, label: item.name });
      });
    }
    setSitesList(arr);
  };

  useEffect(() => {
    if (success) {
      props.onDrawerClose(false);
    }
  }, [success]);

  const handleAddCustomer = (values: any) => {
    if (currentUser?.LOGIN_METHOD === "COMPANY_LOGIN") {
      values.companyCode = currentUser.code;
    } else {
      values.companyCode = currentUser.companyCode;
    }
    values.avatar = avatar;
    values.role = "customer";
    values.vehicles = [];
    values.appInstalled = false;
    values.title = values.title.value;
    values.staffId =
      currentUser?.LOGIN_METHOD === "COMPANY_LOGIN" ? "" : currentUser.id;
    values.registered = registered;
    dispatch(addCustomer(values));
  };

  const onChange = (avatar: string) => setAvatar(avatar);

  const handleChange = (selectedOption: any, setFieldValue: any) => {
    setFieldValue("siteCode", selectedOption.value);
    setSiteName(selectedOption.label);
  };

  return (
    <React.Fragment>
      <div className="add-company">
        <MetaTags>
          <title>Add New Customer</title>
        </MetaTags>
      </div>
      <ReactDrawer
        open={props.open}
        position={"right"}
        onClose={props.onDrawerClose}
      >
        <div className="add-customer-form" style={{ padding: 20 }}>
          <AddAvatar onChange={onChange} title={"Customer"} />
          <Formik
            initialValues={{
              siteCode: "",
              firstName: "",
              lastName: "",
              phone: "",
              email: "",
              title: "",
              postcode: "",
              houseName: "",
              address: "",
            }}
            validationSchema={CustomerSchema}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
              handleAddCustomer(values);
              actions.resetForm({
                values: {
                  siteCode: "",
                  firstName: "",
                  lastName: "",
                  phone: "",
                  email: "",
                  title: "",
                  postcode: "",
                  houseName: "",
                  address: "",
                },
              });
              props.onDrawerClose(false);
            }}
          >
            {({ errors, touched, setFieldValue, values }) => (
              <Form>
                <Row>
                  <Col md="12">
                    <div className="mb-3">
                      <FormGroup>
                        <Label for="siteCode">Site</Label>
                        <Field
                          name="siteCode"
                          render={() => (
                            <SiteSelect
                              siteCode={values.siteCode}
                              siteName={siteName}
                              productList={siteList}
                              handleChange={selectedOption =>
                                handleChange(selectedOption, setFieldValue)
                              }
                            />
                          )}
                        />
                        {errors.siteCode && touched.siteCode ? (
                          <div className="error-message">{errors.siteCode}</div>
                        ) : null}
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <FormGroup>
                        <Label for="firstName">
                          {t("customers.firstName")}
                        </Label>
                        <Field
                          name="firstName"
                          render={({ field }: any) => (
                            <Input {...field} type="text" />
                          )}
                        />
                        {errors.firstName && touched.firstName ? (
                          <div className="error-message">
                            {errors.firstName}
                          </div>
                        ) : null}
                      </FormGroup>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <FormGroup>
                        <Label for="lastName">{t("customers.lastName")}</Label>
                        <Field
                          name="lastName"
                          render={({ field }: any) => (
                            <Input {...field} type="text" />
                          )}
                        />
                        {errors.lastName && touched.lastName ? (
                          <div className="error-message">{errors.lastName}</div>
                        ) : null}
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <FormGroup>
                        <Label for="title">Title</Label>
                        <Field
                          name="title"
                          render={() => (
                            <Select
                              value={values.title}
                              options={titleOptions}
                              onChange={(selectedOption: any) => {
                                setFieldValue("title", selectedOption);
                              }}
                            />
                          )}
                        />
                        {errors.title && touched.title ? (
                          <div className="error-message">{errors.title}</div>
                        ) : null}
                      </FormGroup>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <FormGroup>
                        <Label for="phone">Phone</Label>
                        <Field
                          name="phone"
                          render={({ field }: any) => (
                            <Input {...field} type="text" />
                          )}
                        />
                        {errors.phone && touched.phone ? (
                          <div className="error-message">{errors.phone}</div>
                        ) : null}
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                <Row>

                  <Col md="6">
                    <div className="mb-3">
                      <FormGroup>
                        <Label for="email">Email</Label>
                        <Field
                          name="email"
                          render={({ field }: any) => (
                            <Input {...field} type="email" />
                          )}
                        />
                        {errors.email && touched.email ? (
                          <div className="error-message">{errors.email}</div>
                        ) : null}
                      </FormGroup>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <FormGroup>
                        <Label for="postcode">Post Code</Label>
                        <Field
                          name="postcode"
                          render={({ field }: any) => (
                            <Input {...field} type="text" />
                          )}
                        />
                        {errors.postcode && touched.postcode ? (
                          <div className="error-message">{errors.postcode}</div>
                        ) : null}
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                <Row>

                  <Col md="6">
                    <div className="mb-3">
                      <FormGroup>
                        <Label for="houseName">House Name or Number</Label>
                        <Field
                          name="houseName"
                          render={({ field }: any) => (
                            <Input {...field} type="text" />
                          )}
                        />
                        {errors.houseName && touched.houseName ? (
                          <div className="error-message">
                            {errors.houseName}
                          </div>
                        ) : null}
                      </FormGroup>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <FormGroup>
                        <Label for="address">Address</Label>
                        <Field
                          name="address"
                          render={({ field }: any) => (
                            <Input {...field} type="text" />
                          )}
                        />
                        {errors.address && touched.address ? (
                          <div className="error-message">{errors.address}</div>
                        ) : null}
                      </FormGroup>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="protect"
                        checked={registered}
                        onChange={()=>setRegistered(!registered)}
                      />
                      <label className="form-check-label">Auto registration</label>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                        disabled={props.loading}
                      >
                        {props.loading ? (
                          <SpinnerCircular
                            size={30}
                            thickness={150}
                            speed={150}
                            color="#36ad47"
                            secondaryColor="rgba(0, 0, 0, 0.44)"
                          />
                        ) : (
                          <>Save</>
                        )}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      </ReactDrawer>
    </React.Fragment>
  );
};

export default withRouter(AddCustomer);
