import React from "react";
import { withRouter, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CustomerTab = (props: any) => {
    const { t } = useTranslation();

    return (
        <div className="w-full ms-4">
            <div className="btn-group">
                <Link
                    to="#"
                    className={`btn ${props.isAll ? 'btn-primary text-white shadow' : ' btn-light text-primary shadow'}`}
                    onClick={props.handleIsAll}
                >
                    {t("header.allCustomers")}
                </Link>
                <Link
                    to="#"
                    className={`btn ${props.isAll ? 'btn-light text-primary shadow' : 'btn-primary text-white shadow'}`}
                    onClick={props.handleIsAppUsers}
                >
                    {t("header.appUsers")}
                </Link>
            </div>
        </div>
    );
};

export default withRouter(CustomerTab);