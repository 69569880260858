import { useEffect, useRef, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
//redux
import { useSelector } from "react-redux";
import AddAdmin from "./add-admin";
import { useTranslation } from "react-i18next";
import { IAdmin, IRole } from "src/interfaces/general";
import useAdmins from "src/hooks/useAdmins";
import TablePage, { makeColumns } from "src/components/Common/TablePage";
import ImportCSV from "src/components/Common/ImportCSV";
import { exportToCSV } from "src/helpers/file_helper";

const Admins = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const { currentUser } = useSelector((state: any) => state.login);
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const { admins, loading, success, errorAlert, errorMessage, deleteAdmin, addAdmin, addAdmins } = useAdmins(currentUser?.code||currentUser?.companyCode); 
  const [drawer, setDrawer] = useState<Boolean>(false);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [ role, setRole ] = useState<any>();
  const [isAdmin, setIsAdmin] = useState<Boolean>(false);
  const currentAdmin = useRef<IAdmin>();  
  const hasPermission = currentUser?.permissions?.admins;  

  const defaultSorted: any = [
    {
      dataField: "siteCode", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];   

  useEffect(() => {
    if (currentUser?.LOGIN_METHOD === "COMPANY_LOGIN") {
      setIsAdmin(true);
    }
  }, [currentUser]);
 
  const onEdit = (admin: any) => {
    history.push(`/admins/${admin.id}`);
  };

  const onDelete = (admin: any) => {
    currentAdmin.current = admin;
    setShowConfirmAlert(true);
  };

  const onAdd = () => {
    setDrawer(true);
  }

  const confirmDelete = async () => {
    setShowConfirmAlert(false);
    const result = await deleteAdmin(currentAdmin.current?.id||"");
    if (result){
      setShowSuccessAlert(true);
    }
  };

  const onExport = () => {
    console.log("admins", admins);
    var copy = admins.slice();
    copy.forEach(admin => {
      admin.role = admin.role?.name ?? ""
    })
    exportToCSV(copy, "admins", ["password", "pushTokens", "verifyCode", "verifyCount"]);
    copy = [];
  }
  
  const onDrawerClose = (value: Boolean) => {
    setDrawer(value);
  };

  const onImport = () => {
    setIsModal(true);
  };

  const onClose = () => {
    setIsModal(false)
  };

  const onAddImportData = (data:any) =>{
    addAdmins(
      data.map((item:any)=>({
        companyCode: currentUser?.LOGIN_METHOD === "COMPANY_LOGIN" ? currentUser.code : currentUser.companyCode,
        siteCode: item.sitecode,
        userName: item.username,
        firstName: item.firstname,
        lastName: item.lastname,
        email: item.email,
        phone: item.phone||"none",
        pincode: item.pincode,
        role: {name: item.role},
        password: "Hendy1234"
      }))
    )
    setIsModal(false)
  };

  return (
    <TablePage
      loading={loading}
      metaTitle={t("Admins")}
      data={admins}
      onAdd={onAdd}
      onImport={onImport}
      onExport={isAdmin ? onExport : undefined}
      hasPermission={hasPermission}
      title={t("Admins")}
      description={`(${admins.length})`}
      columns={makeColumns(onDelete, onEdit, header)}
      pageSize={10}
      totalSize={admins.length}
      defaultSorted={defaultSorted}
      footer = {
        <AddAdmin
          open={drawer}
          onDrawerClose={onDrawerClose}
          addAdmin={addAdmin}
          loading={loading}
          success={success}
          errorAlert={errorAlert}
          errorMessage={errorMessage}
        />
      }
      modal = {
        <ImportCSV
          open={isModal}
          onClose={onClose}
          onAddImportData={onAddImportData}
          loading={loading}
        />
      }
        
      // Alerts
      confirmAlert={{
        visible: showConfirmAlert,
        onConfirm: confirmDelete,
        onCancel: () => setShowConfirmAlert(false)
      }}
      successAlert={{
        visible: showSuccessAlert,
        onConfirm: () => setShowSuccessAlert(false)
      }}
    />    
  );
};

export default withRouter(Admins);

const header = [
  {
    dataField: "avatar",
    editable: false,
    text: "Avatar",
    formatter: (_: any, admin: any) => (
      <img
        className="rounded-circle avatar-sm"
        src={admin?.avatar || "https://upload.wikimedia.org/wikipedia/commons/0/09/Man_Silhouette.png"}
        alt=""
      />
    ),
  },    
  {
    dataField: "role.name",
    text: "Role",
    sort: true,
  },
  {
    dataField: "siteCode",
    text: "Site Code",
    sort: true,
  },
  {
    dataField: "userName",
    text: "User Name",
    sort: true,
  },
  {
    dataField: "phone",
    text: "Phone",
    sort: true,
  },
  {
    dataField: "email",
    text: "Email",
    sort: true,
  },
  {
    dataField: "pincode",
    text: "Pin Code",
    sort: true,
  },
  {
    dataField: "menu",
    editable: false,
    text: "Action",
  }
];
