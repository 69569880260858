export const scoreListInfo = [
    {
        dataField: "avatar",
        editable: false,
        text: "Avatar",
        formatter: (_: any, score: any) => (
        <>
            <div>
            <img
                className="rounded-circle avatar-sm"
                src={score?.avatar}
                alt=""
            />
            </div>
        </>
        ),
    },
    {
        dataField: "userName",
        text: "Staff Name",
        sort: true,
    },
    {
        dataField: "siteCode",
        text: "Site Code",
        sort: true,
    },
    {
        dataField: "rankInCompany",
        text: "Company Rank",
        sort: true,
    },
    {
        dataField: "rankInSite",
        text: "Site Rank",
        sort: true,
    },
    {
        dataField: "hendyScore.sharings",
        text: "Invite Sent",
        sort: true,
    },
    // {
    //     dataField: "hendyScore.clickings",
    //     text: "Clickings",
    //     sort: true,
    // },
    {
        dataField: "hendyScore.downloads",
        text: "App Downloads",
        sort: true,
    },
    {
        dataField: "hendyScore.registrations",
        text: "Registered",
        sort: true,
    },
    {
        dataField: "hendyScore.totalScore",
        text: "Total Score",
        sort: true,
    },
]