import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import ReactDrawer from "react-drawer";
import { Col, Row, Label, Input, FormGroup } from "reactstrap";
import { SpinnerCircular } from "spinners-react";
import AddAvatar from "src/components/Common/AddAvatar";
import { IRole } from "src/interfaces/general";
import AddRoleSelector from "./AddRole";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import SweetAlert from "react-bootstrap-sweetalert";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import SiteSelect from "src/components/Common/SiteSelect";
import {
  getAllSitesByCompanyCode,
  getSiteBySiteCode,
} from "../../store/actions";

const AdminSchema = Yup.object().shape({
  siteCode: Yup.string().required("The site code is required."),
  userName: Yup.string().required("The user name is required."),
  firstName: Yup.string().required("The first name is required."),
  lastName: Yup.string().required("The last name is required."),
  phone: Yup.string().required("The phone is required."),
  email: Yup.string().email("Invalid email").required("The email is required."),
  password: Yup.string().required("The password is required."),
  pincode: Yup.string().required("The pincode is required."),
  role: Yup.string().required("Please select the role"),
});

const AddAdmin = (props: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [avatar, setAvatar] = useState("");
  const { currentUser } = useSelector((state: any) => state.login);
  const { sites, authSite } = useSelector((state: any) => state.site);
  const [siteList, setSitesList] = useState<any>([]);
  const [siteName, setSiteName] = useState("");
  const [role, setRole] = useState("");
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [resetForm, setResetForm] = useState(false);

  useEffect(() => {
    if (currentUser?.LOGIN_METHOD === "COMPANY_LOGIN") {
      dispatch(getAllSitesByCompanyCode(currentUser.code));
    }
    if (currentUser?.LOGIN_METHOD === "ADMIN_LOGIN") {
      //dispatch(getSiteBySiteCode(currentUser.siteCode));
      //no longer filtering admin data by site
      dispatch(getAllSitesByCompanyCode(currentUser.companyCode));
      
    }
  }, [currentUser]);

  useEffect(() => {
    //if (currentUser?.LOGIN_METHOD === "ADMIN_LOGIN") {
    //  siteCodeLists(Array(authSite));
    //} else {
      siteCodeLists(sites);
    //}
  }, [sites, authSite]);

  useEffect(() => {
    if (props.success) {
      props.onDrawerClose(false);
    }
  }, [props.success]);

  useEffect(() => {
    setShowErrorAlert(props.errorAlert);
  }, [props.errorAlert]);

  useEffect(() => {
    setErrorMessage(props.errorMessage);
  }, [props.errorMessage]);

  useEffect(() => {
    if (resetForm) {
      setResetForm(false); 
    }
  }, [resetForm]);

  const siteCodeLists = (lists: any) => {
    let arr: { value: any; label: any }[] = [];
    if (lists.length > 0) {
      lists.map((item: any) => {
        arr.push({ value: item.code, label: item.name });
      });
    }
    setSitesList(arr);
  };

  const handleAddAdmin = (values: any) => {
    if (currentUser?.LOGIN_METHOD === "COMPANY_LOGIN") {
      values.companyCode = currentUser.code;
    } else {
      values.companyCode = currentUser.companyCode;
    }
    values.avatar = avatar;
    values.role = role;
    values.hendyScore = {
      sharings: 0,
      clickings: 0,
      openings: 0,
      engagements: 0,
      totalScore: 0,
    };
    props.addAdmin(values);
  };

  const onChange = (avatar: string) => {
    setAvatar(avatar);
  };

  const onChangeRole = (role: IRole, setFieldValue: any) => {
    setRole(role.name);
    setFieldValue("role", role.name);
  };

  const handleChange = (selectedOption: any, setFieldValue: any) => {
    setFieldValue("siteCode", selectedOption.value);
    setSiteName(selectedOption.label);
  };

  const handleClose = () => {
    setResetForm(true);
    props.onDrawerClose(false);
  }

  return (
    <React.Fragment>
      <div className="add-company">
        <MetaTags>
          <title>Add New Admin</title>
        </MetaTags>
      </div>
      <ReactDrawer
        open={props.open}
        position={"right"}
        onClose={handleClose}
      >
        <div className="add-admin-form" style={{ padding: 20 }}>
          <AddAvatar onChange={onChange} title={"Admin"} />
          <Formik
            key={resetForm ? "reset" : "form"} //update the key to reset the form
            initialValues={{
              siteCode: "",
              userName: "",
              firstName: "",
              lastName: "",
              phone: "",
              email: "",
              password: "",
              pincode: "",
              role: "",
            }}
            validationSchema={AdminSchema}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
              handleAddAdmin(values); 
            }}
          >
            {({ errors, touched, setFieldValue, values }) => (
              <Form>
                <Row>
                  <Col md="12">
                    <div className="mb-3">
                      <FormGroup>
                        <Label for="siteCode">Site</Label>
                        <Field
                          name="siteCode"
                          render={() => (
                            <SiteSelect
                              siteCode={values.siteCode}
                              siteName={siteName}
                              productList={siteList}
                              handleChange={(selectedOption)=>handleChange(selectedOption, setFieldValue)}
                            />
                          )}
                        />
                        {errors.siteCode && touched.siteCode ? (
                          <div className="error-message">{errors.siteCode}</div>
                        ) : null}
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <FormGroup>
                        <Label for="firstName">First Name</Label>
                        <Field
                          name="firstName"
                          render={({ field }: any) => (
                            <Input {...field} type="text" />
                          )}
                        />
                        {errors.firstName && touched.firstName ? (
                          <div className="error-message">
                            {errors.firstName}
                          </div>
                        ) : null}
                      </FormGroup>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <FormGroup>
                        <Label for="lastName">Last Name</Label>
                        <Field
                          name="lastName"
                          render={({ field }: any) => (
                            <Input {...field} type="text" />
                          )}
                        />
                        {errors.lastName && touched.lastName ? (
                          <div className="error-message">{errors.lastName}</div>
                        ) : null}
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <FormGroup>
                        <Label for="userName">User Name</Label>
                        <Field
                          name="userName"
                          render={({ field }: any) => (
                            <Input {...field} type="text" />
                          )}
                        />
                        {errors.userName && touched.userName ? (
                          <div className="error-message">{errors.userName}</div>
                        ) : null}
                      </FormGroup>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <FormGroup>
                        <Label for="password">Password</Label>
                        <Field
                          name="password"
                          render={({ field }: any) => (
                            <Input {...field} type="password" />
                          )}
                        />
                        {errors.password && touched.password ? (
                          <div className="error-message">{errors.password}</div>
                        ) : null}
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <FormGroup>
                        <Label for="phone">Phone</Label>
                        <Field
                          name="phone"
                          render={({ field }: any) => (
                            <Input {...field} type="text" />
                          )}
                        />
                        {errors.phone && touched.phone ? (
                          <div className="error-message">{errors.phone}</div>
                        ) : null}
                      </FormGroup>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <FormGroup>
                        <Label for="email">Email</Label>
                        <Field
                          name="email"
                          render={({ field }: any) => (
                            <Input {...field} type="email" />
                          )}
                        />
                        {errors.email && touched.email ? (
                          <div className="error-message">{errors.email}</div>
                        ) : null}
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <FormGroup>
                        <Label for="pincode">PinCode</Label>
                        <Field
                          name="pincode"
                          render={({ field }: any) => (
                            <Input {...field} type="text" />
                          )}
                        />
                        {errors.pincode && touched.pincode ? (
                          <div className="error-message">{errors.pincode}</div>
                        ) : null}
                      </FormGroup>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <label> Role </label>
                      <AddRoleSelector
                        method="add"
                        onChangeRole={(role: IRole) =>
                          onChangeRole(role, setFieldValue)
                        }
                      />
                    </div>
                    {errors.role && touched.role ? (
                      <div className="error-message">{errors.role}</div>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                        disabled={props.loading}
                      >
                        {props.loading ? (
                          <SpinnerCircular
                            size={30}
                            thickness={150}
                            speed={150}
                            color="#36ad47"
                            secondaryColor="rgba(0, 0, 0, 0.44)"
                          />
                        ) : (
                          <>Save</>
                        )}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
        {!showErrorAlert && (
          <SweetAlert
            warning
            title={errorMessage}
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => setShowErrorAlert(true)}
            onCancel={() => setShowErrorAlert(true)}
          />
        )}
      </ReactDrawer>
    </React.Fragment>
  );
};

export default withRouter(AddAdmin);
