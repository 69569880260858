import React, { useEffect, useState } from "react";
import { withRouter, Link, useParams } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Icon from "@ailibs/feather-react-ts";
import MetaTags from "react-meta-tags";
import useRoles from "src/hooks/useRoles";
import { SpinnerCircular } from "spinners-react";
import { useTranslation } from "react-i18next";

const EditRole = (props: any) => {
  const { t } = useTranslation();
  const { docid } = useParams<any>();

  const { singleRole, loading, updateRole, success, fetchRoleById } =
    useRoles();
  const [permissions, setPermissions] = useState({
    companies: false,
    sites: false,
    claims: false,
    admins: false,
    customers: false,
    bookings: false,
    checkins: false,
    notifications: false,
    keylocker: false,
    comms: false,
    checkinSettings: false,
    bookingTool: false,
    products: false,
    vehicles: false,
    quotes: false,
    invocies: false,
    paymentSetting: false,
    paymentHistory: false,
    geofence: false,
    warranty: false,
    saleOfProducts: false,
  });

  useEffect(() => {
    fetchRoleById(docid);
  }, []);

  useEffect(() => {
    if (singleRole?.permissions) {
      setPermissions(singleRole?.permissions);
    } else {
      setPermissions({
        companies: false,
        sites: false,
        claims: false,
        admins: false,
        customers: false,
        bookings: false,
        checkins: false,
        notifications: false,
        keylocker: false,
        comms: false,
        checkinSettings: false,
        bookingTool: false,
        products: false,
        vehicles: false,
        quotes: false,
        invocies: false,
        paymentSetting: false,
        paymentHistory: false,
        geofence: false,
        warranty: false,
        saleOfProducts: false,
      });
    }
  }, [singleRole]);

  const handleEditRole = (role: any) => {
    role.permissions = permissions;
    role.companyCode = singleRole?.companyCode;
    console.log('role:', role) ;
    console.log('permissions:', permissions) ;
    updateRole(docid, role);
  };

  const handlePermissionCheck = (e: any) => {
    setPermissions({ ...permissions, [e.target.name]: e.target.checked });
    console.log('permissions:::::::::::::::::', permissions);
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Edit Role</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <Row>
                    <div className="col-sm order-2 order-sm-1">
                      <div className="d-flex align-items-start mt-3 mt-sm-0">
                        <div style={{ paddingRight: 10 }}>
                          <Link to="/roles" className="has-arrow">
                            <Icon name="arrow-left" />
                          </Link>
                        </div>
                        <div className="flex-grow-1">
                          <div>
                            <h5 className="font-size-16 mb-1">
                              Title: Role Detail
                            </h5>
                            <p className="text-muted font-size-13">
                              Description: RoleId: {singleRole?.id}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <AvForm
            onValidSubmit={(e: any, values: any) => {
              handleEditRole(values);
            }}
          >
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="name"
                    label="Name"
                    type="text"
                    errorMessage="Invalid Name"
                    validate={{
                      required: { value: true },
                    }}
                    value={singleRole?.name || ""}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="companyCode"
                    label="Company Code"
                    type="text"
                    disabled={true}
                    errorMessage="Invalid Company Code"
                    validate={{
                      required: { value: true },
                    }}
                    value={singleRole?.companyCode || ""}
                  />
                </div>
              </Col>
            </Row>

            <Row className="mt-4 p-3 directory-container">
              <h4>Permissions</h4>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="companies"
                  checked={permissions?.companies}
                  onChange={e => handlePermissionCheck(e)}
                />
                <label className="form-check-label">Companies</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="sites"
                  checked={permissions?.sites}
                  onChange={e => handlePermissionCheck(e)}
                />
                <label className="form-check-label">Sites</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="claims"
                  checked={permissions?.claims}
                  onChange={e => handlePermissionCheck(e)}
                />
                <label className="form-check-label">Claims</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="admins"
                  checked={permissions?.admins}
                  onChange={e => handlePermissionCheck(e)}
                />
                <label className="form-check-label">Admins</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="customers"
                  checked={permissions?.customers}
                  onChange={e => handlePermissionCheck(e)}
                />
                <label className="form-check-label">Customers</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="bookings"
                  checked={permissions?.bookings}
                  onChange={e => handlePermissionCheck(e)}
                />
                <label className="form-check-label">Bookings</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="checkins"
                  checked={permissions?.checkins}
                  onChange={e => handlePermissionCheck(e)}
                />
                <label className="form-check-label">Checkins</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="notifications"
                  checked={permissions?.notifications}
                  onChange={e => handlePermissionCheck(e)}
                />
                <label className="form-check-label">Notifications</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="keylocker"
                  checked={permissions?.keylocker}
                  onChange={e => handlePermissionCheck(e)}
                />
                <label className="form-check-label">Keylocker</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="comms"
                  checked={permissions?.comms}
                  onChange={e => handlePermissionCheck(e)}
                />
                <label className="form-check-label">Comms</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="checkinSettings"
                  checked={permissions?.checkinSettings}
                  onChange={e => handlePermissionCheck(e)}
                />
                <label className="form-check-label">Checkin Settings</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="bookingTool"
                  checked={permissions?.bookingTool}
                  onChange={e => handlePermissionCheck(e)}
                />
                <label className="form-check-label">Booking Tool</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="products"
                  checked={permissions?.products}
                  onChange={e => handlePermissionCheck(e)}
                />
                <label className="form-check-label">Products</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="geofence"
                  checked={permissions?.geofence}
                  onChange={e => handlePermissionCheck(e)}
                />
                <label className="form-check-label">GeoFence</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="warranty"
                  checked={permissions?.warranty}
                  onChange={e => handlePermissionCheck(e)}
                />
                <label className="form-check-label">Warranty</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="vehicles"
                  checked={permissions?.vehicles}
                  onChange={e => handlePermissionCheck(e)}
                />
                <label className="form-check-label">Vehicles</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="quotes"
                  checked={permissions?.quotes}
                  onChange={e => handlePermissionCheck(e)}
                />
                <label className="form-check-label">Quotes</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="invocies"
                  checked={permissions?.invocies}
                  onChange={e => handlePermissionCheck(e)}
                />
                <label className="form-check-label">Invoices</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="paymentSetting"
                  checked={permissions?.paymentSetting}
                  onChange={e => handlePermissionCheck(e)}
                />
                <label className="form-check-label">Payment Setting</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="paymentHistory"
                  checked={permissions?.paymentHistory}
                  onChange={e => handlePermissionCheck(e)}
                />
                <label className="form-check-label">Payment History</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="saleOfProducts"
                  checked={permissions?.saleOfProducts}
                  onChange={e => handlePermissionCheck(e)}
                />
                <label className="form-check-label">Sale of Products</label>
              </div>
            </Row>

            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="submit"
                    className="btn btn-success save-user"
                    disabled={loading}
                  >
                    {loading ? (
                      <SpinnerCircular
                        size={30}
                        thickness={150}
                        speed={150}
                        color="#36ad47"
                        secondaryColor="rgba(0, 0, 0, 0.44)"
                      />
                    ) : (
                      <>{t("customers.Update")}</>
                    )}
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(EditRole);
