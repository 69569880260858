import moment from "moment";
import { storage } from "./firebase_config";
import { v4 } from 'uuid'

class ApiUtils {



    constructor() {

    }


    async uploadFile(file: any) {
        const path = `/avatar/${file.name}`;
        const ref = storage.ref(path);


        await ref.put(file);
        const url = await ref.getDownloadURL();
        return url;
    }

    isExpired(date: any) {
        return moment(date).diff(moment(), 'd') <= 0;
    }

    uploadBase64(base64: string, bucket: string,
        cb?: (snapShot: any) => void): Promise<string> {
        const filename = v4() + ".pdf";
        const reference = storage.ref(`/${bucket}/${filename}`);
        const task = reference.putString(base64, "base64");
        return new Promise((resolve, reject) => {
            task.on("state_changed", cb);
            task.then(() => {
                const url = reference.getDownloadURL();
                resolve(url);
            }).catch(reject);
        })
    }
}


export default new ApiUtils();