import { useState, useEffect } from "react";
import { getVehiclesByCustomerIdAsync } from "src/helpers/backend_helper";
import { IVehicle } from "src/interfaces/general";

const useVehicles = (customerId?: string) => {

    const [vehicles, setVehicles] = useState<Array<IVehicle>>([]);
    const [loading, setLoading] = useState(false);

    const fetchVehicles = () => {
        setLoading(true);
        if (customerId) {
            getVehiclesByCustomerIdAsync(customerId)
            .then(res=>{
                if(res.success){
                    setVehicles(res.result||[]);
                }
            }).catch(console.log).finally(()=>setLoading(false));
        }
    };

    useEffect (()=> {
        fetchVehicles()
    },[customerId]);

    return {
        vehicles,
        loading,
    };
}

export default useVehicles;