import * as actionTypes from './actionTypes';

export const INIT_STATE: any = {
  claims: [],
  singleClaim: {},
  error: null,
  success: false,
  loading: false,
  confirm_del: false,
};

const claims = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_CLAIMS_BY_SITE_CODE:
    case actionTypes.FETCH_CLAIMS_BY_COMPANY_CODE:
    case actionTypes.GET_CLAIM_BY_ID:
    case actionTypes.MARK_CLAIM:

    case actionTypes.SEND_MESSAGE:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case actionTypes.FETCH_CLAIMS_BY_SITE_CODE_SUCCESS:
      console.log("action.payload::::::", action.payload);
      return {
        ...state,
        loading: false,
        claims: action.payload,
      };

    case actionTypes.FETCH_CLAIMS_BY_SITE_CODE_FAILURE:
    case actionTypes.GET_CLAIM_BY_ID_FAILURE:

    case actionTypes.FETCH_CLAIMS_BY_COMPANY_CODE_SUCCESS:
      console.log("action.payload::", action.payload);
      return {
        ...state,
        loading: false,
        claims: action.payload,
      };
    case actionTypes.FETCH_CLAIMS_BY_COMPANY_CODE_FAILURE:

    case actionTypes.MARK_CLAIM_FAILURE:
    case actionTypes.SEND_MESSAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.GET_CLAIM_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        singleClaim: action.payload,
      };

    case actionTypes.MARK_CLAIM_SUCCESS:
      return {
        ...state,
        loading: false,
        claims: state.claims.map((claim: any) => {
          if (claim.id === action.payload) {
            claim.resolved = !claim.resolved;
          }
          return claim;
        }),
      };

    case actionTypes.SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };

    default:
      return state;
  }
};

export default claims;