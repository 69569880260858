import { useState, useEffect, useMemo } from "react";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import TablePage, { makeColumns } from "src/components/Common/TablePage";
import useSaleOfProducts from "src/hooks/useSaleOfProducts";
import moment from "moment";
import { ISaleOfProduct } from "src/interfaces/general";
import { getContractByAgreementAsync } from "src/helpers/backend_helper";

import { filter } from "lodash";
import React from "react";
import { exportToCSV } from "src/helpers/file_helper";

// const isMatchProduct = (product: ISaleOfProduct, contract: any): boolean =>
//   moment(product.createdAt).format('YYYY-MM-DD') === moment(contract?.ContractProducts?.[0]?.StartDate).format('YYYY-MM-DD')
//   && (contract?.ContractProducts || []).map((item: any) => item?.CoverLevel.trim()).includes(product.productName)

const SaleOfProductsTable = (props: any) => {
  const { t } = useTranslation();
  const { currentUser } = useSelector((state: any) => state.login);
  const { saleOfProducts, loading } = useSaleOfProducts();
  const searchPara = props.data;
  const [filteredProducts, setFilteredProducts] = useState<any>([]);
  //const [mergedProducts, setMergedProducts] = useState<any>([]);
  const [contractStates, setContractStates] = useState<any>({});


  const hasPermission = currentUser?.permissions?.saleOfProducts;
  //let mergedData;

  useEffect(() => {
    setFilteredProducts(
      saleOfProducts.filter(item => {
        return (
          // item.admin.code === currentUser.code &&
          (item.createdAt === undefined ||
            searchPara.startDate < item.createdAt) &&
          (item.createdAt === undefined ||
            searchPara.endDate > item.createdAt) &&
          (searchPara.state === undefined || searchPara.state === item.state) &&
          (searchPara.site === undefined || searchPara.site === item.site) &&
          (searchPara.userName === undefined || searchPara.userName === item.admin.userName) &&
          (searchPara.product === undefined ||
            searchPara.product === item.productName) &&
          (searchPara.cover === undefined || searchPara.cover === item.cover) &&
          searchPara.price[0] < item.price &&
          searchPara.price[1] > item.price &&
          (searchPara.role === undefined ||
            searchPara.role === item.job_role) &&
          (searchPara.cc === undefined || searchPara.cc === item.cc)
        );
      })
    );

  }, [searchPara, saleOfProducts]);

  /*useEffect(() => {
     if (filteredProducts.length > 0) {
      let agreementArray: string[] = [];
      let contractsArray: any = {};
      filteredProducts.map((product: ISaleOfProduct) => {
        if (!agreementArray.includes(product.agreement.toUpperCase())) {
          agreementArray.push(product.agreement.toUpperCase());
        }
      });

      Promise.all(
        agreementArray.map((agreement: string) =>
          getContractByAgreementAsync(agreement)
        )
      ).then((results: any[]) => {
        setContractStates(
          results.reduce(
            (accum, item, index) => ({
              ...accum,
              [agreementArray[index]]: item,
            }),
            {}
          )
        );
      });
    } else {
    } 
  }, [filteredProducts]);*/

  useEffect(() => {
    /*     mergedData =
          filteredProducts.length > 0
            ? filteredProducts.map((product: ISaleOfProduct) => {
              const contractInfo: [] =
                contractStates[product.agreement.toUpperCase()]?.result || [];
              const foundContract: any = contractInfo.find(
                (item: any) => product?.contractNumber === item?.ContractNumber
              );
              if (foundContract) {
                return {
                  ...product,
                  state: foundContract?.ContractStatusDescription ?? "",
                };
              }
              return product;
            })
            : []; */
    /* setMergedProducts(
      mergedData.filter((item: any) => {
        return (
          searchPara.state === undefined || searchPara.state === item.state
        );
      })
    ); */
    //const mine = filteredProducts.filter((item: any) => item.admin.id === currentUser.id || !currentUser.siteCode);
    props.onChangeValues?.({
      quote: filteredProducts.filter((item: any) => item.state === 'Quote').length,
      live: filteredProducts.filter((item: any) => item.state === 'Live').length,
      cancelled: filteredProducts.filter((item: any) => item.state === 'Cancelled').length,
      other: filteredProducts.filter((item: any) => item.state !== 'Cancelled' && item.state !== 'Live' && item.state !== 'Quote').length
    })
  }, [filteredProducts, contractStates]);

  useEffect(() => {
    if (filteredProducts.length > 0) {
      let totalSum = 0;
      totalSum = filteredProducts.filter((item: any) => {
        return (
          item.state === "Live"
        )
      }).reduce((sum: number, item: ISaleOfProduct) => (sum = sum * 1 + Number(item.price) * 1), 0);
      if (totalSum > 0) {
        props.callback(Math.round(totalSum * 100) / 100);
      } else {
        props.callback(0);
      }
    } else {
      props.callback(0);
    }
  }, [filteredProducts]);

  const defaultSorted: any = [
    {
      dataField: "code", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const exportData = () => {

    var exportItems: any[] = []
    var dataClone: any[] = JSON.parse(JSON.stringify(filteredProducts));

    dataClone.map(o => {
      exportItems.push({
        CompanyCode: o.companyCode,
        Agreement: o.agreement,
        ContractNumber: o.contractNumber,
        Status: o.state,
        Created: new Date(o.createdAt).toLocaleString(),
        Product: o.productName,
        Cover: o.cover,
        Price: o.price,
        Deposit: o.deposit ?? 0,
        Username: o.admin?.name ?? "",
        SiteName: o.site,
        Department: o.department,
        JobRole: o.job_role,
        CC: o.cc,
        Policy: o.policy
      })
    })

    exportToCSV(exportItems, "sale-of-products", []);
  }

  return (
    <TablePage
      loading={loading}
      metaTitle={t("Sale of Products")}
      // data={filteredProducts}
      data={filteredProducts}
      hasPermission={hasPermission}
      title={t("Sale of Products")}
      description={`(${filteredProducts.length})`}
      columns={makeColumns(null, null, header)}
      pageSize={10}
      totalSize={filteredProducts.length}
      defaultSorted={defaultSorted}
      onExport={exportData}
    />
  );
};

export default withRouter(SaleOfProductsTable);

const header = [
  {
    dataField: "companyCode",
    text: "Company Code",
    sort: true,
  },
  {
    dataField: "agreement",
    text: "Agreement",
    sort: true,
  },
  {
    dataField: "state",
    text: "Status",
    formatter: (_: any, saleOfProduct: any) =>
      saleOfProduct.state.replace('Quote', 'Invite Sent'),
    sort: true,
  },
  {
    dataField: "createdAt",
    text: "Created",
    formatter: (_: any, saleOfProduct: any) =>
      moment(saleOfProduct.createdAt).format("DD/MM/YYYY"),
    sort: true,
  },
  {
    dataField: "productName",
    text: "Products",
    sort: true,
  },
  {
    dataField: "cover",
    text: "Cover",
    sort: true,
  },
  {
    dataField: "price",
    text: "Price",
    formatter: (_: any, saleOfProduct: any) =>
      "£" + saleOfProduct.price?.toFixed(2),
    sort: true,
  },
  {
    dataField: "deposit",
    text: "Deposit",
    formatter: (_: any, saleOfProduct: any) =>
      saleOfProduct.deposit ? "£" + saleOfProduct.deposit : "-",
    sort: true,
  },
  {
    dataField: "username",
    text: "Username",
    formatter: (_: any, saleOfProduct: any) =>
      saleOfProduct?.admin?.userName || saleOfProduct?.admin?.name,
    sort: true,
  },
  {
    dataField: "site",
    text: "Site Name",
    sort: true,
  },
  {
    dataField: "department",
    text: "Department",
    sort: true,
  },
  {
    dataField: "job_role",
    text: "Job Role",
    sort: true,
  },
  {
    dataField: "cc",
    text: "CC",
    sort: true,
  },
  {
    dataField: "policy",
    text: "Policy",
    formatter: (_: any, saleOfProduct: any) => (
      <a target="_blank" href={saleOfProduct.policy}>
        <i className="mdi mdi-eye" id="edittooltip"></i>
      </a>
    ),
  },
];
