import { useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from 'moment';
import { useTranslation } from "react-i18next";
import useNotifications from "src/hooks/useNotifications";
import TablePage, { makeColumns } from "src/components/Common/TablePage";
import { Tag } from "src/components/Common/SimpleComponents";

const Notification = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { currentUser } = useSelector((state: any) => state.login);
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [currentNotification, setCurrentNotification] = useState<any>([]);
  const { notifications, loading, deleteNotification } = useNotifications(currentUser?.code||currentUser?.companyCode);

  const hasPermission = currentUser?.permissions?.notifications;

  const onDelete = (notification: any) => {
    setCurrentNotification(notification);
    setShowConfirmAlert(true);
  };

  const confirmDelete = async () => {
    setShowConfirmAlert(false);
    const result = await deleteNotification(currentNotification?.id || "");
    if (result) {
      setShowSuccessAlert(true);
    }
  };

  const onAdd = () => {
    history.push(`/notifications/add`);
  }

  return (
    <TablePage
      loading={loading}
      metaTitle={t("Notifications")}
      data={notifications}
      onAdd={onAdd}
      hasPermission={hasPermission}
      title={t("Notifications")}
      description={`(${notifications.length})`}
      columns={makeColumns(onDelete, null, header)}
      pageSize={10}
      totalSize={notifications.length}

      // Alerts
      confirmAlert={{
        visible: showConfirmAlert,
        onConfirm: confirmDelete,
        onCancel: () => setShowConfirmAlert(false)
      }}
      successAlert={{
        visible: showSuccessAlert,
        onConfirm: () => setShowSuccessAlert(false)
      }}
    />
  );
};

export default withRouter(Notification);

const header = [
  {
    dataField: "phone",
    text: "Customer Phone",
    sort: true,
  },
  {
    dataField: "siteCode",
    text: "Site Code",
    sort: true,
  },
  {
    dataField: "regNo",
    text: "Reg No",
    sort: true,
  },
  {
    dataField: "body",
    text: "Body",
    sort: true,
  },
  {
    dataField: "subject",
    text: "Subject",
    sort: true,
  },
  {
    dataField: "date",
    text: "Sent",
    formatter: (_: any, Notification: any) => (
      moment(Notification?.createdAt).format("DD/MM/YYYY")
    ),
  },
  {
    dataField: "type",
    text: "Type",
    formatter: (_: any, role: any) => (
      <Tag type = "" ><span>{role.type.toUpperCase()}</span></Tag>
    ),
  },
  {
    dataField: "unread",
    text: "Unread",
    sort: true,
  },
  {
    dataField: "menu",
    editable: false,
    text: "Action"
  }
];