import { CustomerTypes } from "./actionTypes";


export const getAllCustomersBySiteCode = (siteCode: string) => ({
  type: CustomerTypes.GET_CUSTOMERS_BY_SITECODE,
  payload: siteCode
});

export const getCustomersBySiteCodeSuccess = (customers: any) => ({
  type: CustomerTypes.GET_CUSTOMERS_BY_SITECODE_SUCCESS,
  payload: customers,
});

export const getCustomersBySiteCodeFail = (error: any) => ({
  type: CustomerTypes.GET_CUSTOMERS_BY_SITECODE_FAIL,
  payload: error,
});



export const getAllCustomersByCompanyCode = (companyCode: string) => ({
  type: CustomerTypes.GET_CUSTOMERS_BY_COMPANYCODE,
  payload: companyCode
});

export const getAllCustomersByCompanyCodeSuccess = (customers: any) => ({
  type: CustomerTypes.GET_CUSTOMERS_BY_COMPANYCODE_SUCCESS,
  payload: customers,
});

export const getAllCustomersByCompanyCodeFail = (error: any) => ({
  type: CustomerTypes.GET_CUSTOMERS_BY_COMPANYCODE_FAIL,
  payload: error,
});


export const getAllCustomersAndVehiclesByCompanyCode = (companyCode: string) => ({
  type: CustomerTypes.GET_CUSTOMERS_AND_VEHICLES_BY_COMPANYCODE,
  payload: companyCode
});

export const getAllCustomersAndVehiclesByCompanyCodeSuccess = (customers: any) => ({
  type: CustomerTypes.GET_CUSTOMERS_AND_VEHICLES_BY_COMPANYCODE_SUCCESS,
  payload: customers,
});

export const getAllCustomersAndVehiclesByCompanyCodeFail = (error: any) => ({
  type: CustomerTypes.GET_CUSTOMERS_AND_VEHICLES_BY_COMPANYCODE_FAIL,
  payload: error,
});


export const addCustomer = (customer: any) => ({
  type: CustomerTypes.ADD_CUSTOMER,
  payload: customer,
});

export const addCustomerSuccess = (customer: any) => ({
  type: CustomerTypes.ADD_CUSTOMER_SUCCESS,
  payload: customer,
});

export const addCustomerFail = (error: any) => ({
  type: CustomerTypes.ADD_CUSTOMER_FAIL,
  payload: error,
});

//delete
export const deleteCustomerByDocId = (docid: string) => ({
  type: CustomerTypes.DELETE_CUSTOMER,
  payload: docid,
});

export const deleteCustomerByDocIdSuccess = (docid: any) => ({
  type: CustomerTypes.DELETE_CUSTOMER_SUCCESS,
  payload: docid,
});

export const deleteCustomerByDocIdFail = (error: any) => ({
  type: CustomerTypes.DELETE_CUSTOMER_FAIL,
  payload: error,
});

export const getCustomerByDocId = (docid: string) => ({
  type: CustomerTypes.GET_CUSTOMER_BY_DOCID,
  payload: docid
})

export const getCustomerByDocIdSuccess = (customer: any) => ({
  type: CustomerTypes.GET_CUSTOMER_BY_DOCID_SUCCESS,
  payload: customer,
});

export const getCustomerByDocIdFail = (error: any) => ({
  type: CustomerTypes.GET_CUSTOMER_BY_DOCID_FAIL,
  payload: error,
});

export const updateCustomerByDocId = (docid: string, customer: any, history: any) => ({
  type: CustomerTypes.UPDATE_CUSTOMER_BY_DOCID,
  payload: { docid, customer, history }
})

export const updateCustomerByDocIdSuccess = (customer: any) => ({
  type: CustomerTypes.UPDATE_CUSTOMER_BY_DOCID_SUCCESS,
  payload: customer,
});

export const updateCustomerByDocIdFail = (error: any) => ({
  type: CustomerTypes.UPDATE_CUSTOMER_BY_DOCID_FAIL,
  payload: error,
});




