import company from '../company/reducer';
import * as actionTypes from './actionTypes';

export const fetchClaimsBySiteCode = (companyCode: string, siteCode: string) => ({
  type: actionTypes.FETCH_CLAIMS_BY_SITE_CODE,
  payload: { companyCode, siteCode },
});

export const fetchClaimsByCompanyCode = (companyCode: string) => ({
  type: actionTypes.FETCH_CLAIMS_BY_COMPANY_CODE,
  payload: { companyCode },
});

export const getClaimById = (customerId: string) => ({
  type: actionTypes.GET_CLAIM_BY_ID,
  payload: { customerId },
});

export const markClaim = (claimId: string) => ({
  type: actionTypes.MARK_CLAIM,
  payload: { claimId },
});

export const sendMessage = (claimId: string, notes: string) => ({
  type: actionTypes.SEND_MESSAGE,
  payload: { claimId, notes },
});