export const FETCH_CLAIMS_BY_SITE_CODE = 'FETCH_CLAIMS_BY_SITE_CODE';
export const FETCH_CLAIMS_BY_SITE_CODE_SUCCESS = 'FETCH_CLAIMS_BY_SITE_CODE_SUCCESS';
export const FETCH_CLAIMS_BY_SITE_CODE_FAILURE = 'FETCH_CLAIMS_BY_SITE_CODE_FAILURE';

export const FETCH_CLAIMS_BY_COMPANY_CODE = 'FETCH_CLAIMS_BY_COMPANY_CODE';
export const FETCH_CLAIMS_BY_COMPANY_CODE_SUCCESS = 'FETCH_CLAIM_BY_COMPANY_CODE_SUCCESS';
export const FETCH_CLAIMS_BY_COMPANY_CODE_FAILURE = 'FETCH_CLAIM_BY_COMPANY_CODE_FAILURE';

export const GET_CLAIM_BY_ID = 'GET_CLAIM_BY_ID';
export const GET_CLAIM_BY_ID_SUCCESS = 'GET_CLAIM_BY_ID_SUCCESS';
export const GET_CLAIM_BY_ID_FAILURE = 'GET_CLAIM_BY_ID_FAILURE';

export const MARK_CLAIM = 'MARK_CLAIM';
export const MARK_CLAIM_SUCCESS = 'MARK_CLAIM_SUCCESS';
export const MARK_CLAIM_FAILURE = 'MARK_CLAIM_FAILURE';

export const SEND_MESSAGE = 'SEND_MESSAGE';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAILURE = 'SEND_MESSAGE_FAILURE';