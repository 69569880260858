import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import styled from "styled-components";
import Icon from "@ailibs/feather-react-ts";
import { ThemeColors } from "src/constants/colors";
import useAnalytics from "src/hooks/useAnalytics";
import { IAnalytic } from "./types";
import { Link } from "react-router-dom";

const Tabs: Array<{
  title: string;
  key?: string;
  events?: string[];
}> = [
  {
    title: "Overview",
    events: [
      "first_open",
      "app_open",
      "app_update",
      "app_remove",
      "app_clear_data",
      "delete_account",
    ],
  },
  { title: "Home", key: "carousel" },
  { title: "Left Drawer", key: "drawer" },
  {
    title: "Notification Summary",
    events: [
      "notification_dismiss",
      "notification_open",
      "notification_receive",
      "notification_click"
    ],
  },
  {
    title: "Extra",
    events: ["screen_view", "session_start", "user_engagement"],
  },
];

const EventNames: Array<{
  key: string;
  value: string;
}> = [
  { key: "APP CLEAR DATA", value: "APP CLEAR DATA" },
  { key: "APP OPEN", value: "Opened APP" },
  { key: "APP REMOVE", value: "Unistalled APP" },
  { key: "APP UPDATE", value: "Updated APP via IOS/Playstore" },
  { key: "DELETE ACCOUNT", value: "Deleted Account" },
  { key: "SCREEN VIEW", value: "Total Screen Views" },
  { key: "SESSION START", value: "Engaged Sessions" },
  { key: "USER ENGAGEMENT", value: "Engaged Sessions - Actions Taken" },
  { key: "FIRST OPEN", value: "App installed" },
  { key: "CAROUSEL ASSIST", value: "ASSIST" },
  { key: "CAROUSEL BRANDS", value: "BRANDS" },
  { key: "CAROUSEL BUYONLINE", value: "BUY ONLINE" },
  { key: "CAROUSEL CALL", value: "CALL" },
  { key: "CAROUSEL CHARGES", value: "CITY CHARGES" },
  { key: "CAROUSEL CUSTOMIZE", value: "CUSTOMISE" },
  { key: "CAROUSEL DOCUMENT", value: "HISTORY & DOCUMENTS" },
  { key: "CAROUSEL EXTRA SERVICES", value: "EXTRA SERVICES" },
  { key: "CAROUSEL FUEL", value: "FUEL" },
  { key: "CAROUSEL GAP", value: "GAP" },
  { key: "CAROUSEL HEALTHCHECK", value: "HEALTHCHECK" },
  { key: "CAROUSEL HENDYCARE", value: "HENDYCARE" },
  { key: "CAROUSEL NAVIGATION", value: "NAVIGATION" },
  { key: "CAROUSEL ONLINEBOOKING", value: "ONLINE BOOKING" },
  { key: "CAROUSEL PAINT", value: "PAINT & ALLOYS" },
  { key: "CAROUSEL PARK", value: "PARK" },
  { key: "CAROUSEL RECOVERY", value: "RECOVERY" },
  { key: "CAROUSEL RENTAL", value: "RENTAL" },
  { key: "CAROUSEL ROADS", value: "ROADS & TOLLS" },
  { key: "CAROUSEL SELL", value: "SELL MY VEHICLE" },
  { key: "CAROUSEL SERVICEOPTIONS", value: "SERVICE OPTIONS" },
  { key: "CAROUSEL SERVICEPLAN", value: "SERVICE PLAN" },
  { key: "CAROUSEL WARRANTY", value: "WARRANTY" },
  { key: "NOTIFICATION DISMISS", value: "Notifications - Dismissed" },
  { key: "NOTIFICATION OPEN", value: "Notifications - Opened" },
  { key: "NOTIFICATION RECEIVE", value: "Notifications - Received" },
  { key: "NOTIFICATION CLICK", value: "Notifications - Click" },
  { key: "DRAWER DOCUMENT", value: "My Documents" },
  { key: "DRAWER HGCR", value: "Hendy Go Car Subscription" },
  { key: "DRAWER HSHC", value: "Hendy Health Check" },
  { key: "DRAWER INBOX", value: "Inbox" },
  { key: "DRAWER LOGOUT", value: "Logout" },
  { key: "DRAWER MOT", value: "Motability" },
  { key: "DRAWER SHAREAPP", value: "Share App" },
  { key: "DRAWER SOCIALMEDIA", value: "Social Media" },
  { key: "DRAWER UPGRADEAPP", value: "Upgrade App" },
];

const Dashboard = () => {
  const { loading, analytics, fetchAnalytics } = useAnalytics();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [startDate, setStartDate] = useState<any>(
    moment().subtract(7, "days").toDate()
  );
  const [endDate, setEndDate] = useState<any>(new Date());

  const handleSelect = (index: number) => {
    setTabIndex(index);
  };

  const data = analytics.filter(item => {
    if (Tabs[tabIndex].events) {
      return Tabs[tabIndex].events?.includes(item.id);
    }
    return item.id.includes(Tabs[tabIndex].key || "");
  });

  const totalRecords = EventNames.reduce((sum, item) => {
    const matchingItem = analytics.find(
      element => element.event_name === item.key
    );
    if (matchingItem) {
      return sum + matchingItem.count;
    }
    return sum;
  }, 0);

  const maxAmount = Math.max(...data.map(item => item.count));

  const calcPercent = (count: number) => {
    return (count / maxAmount) * 60;
  };

  const calcTotalCount = (data : IAnalytic[]) => {
      return data.reduce((sum, item) => sum + item.count, 0)
  }

  const handleStartDateChange = (date: any) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date: any) => {
    setEndDate(date);
  };

  const onSelectStartDate = (date: any) => {
    setStartDate(date);
    fetchAnalytics(date, endDate);
  };

  const onSelectEndDate = (date: any) => {
    setEndDate(date);
    fetchAnalytics(startDate, date);
  };

  const isConvertedNewEventName = (oldName: string) => {
    const newEventName = EventNames.find(item => item.key === oldName)?.value ?? "OTHER";
    return newEventName;
  };

  const onExportCSV = () => {
    let csvRows: any[] = ["Event Name, Count", "\n"];
    analytics.map((item) => {
      const row = isConvertedNewEventName(item.event_name).concat(",", item.count.toString());
      console.log(row);
      csvRows.push(row);
      csvRows.push("\n");
    })
    const blob = new Blob(csvRows, {type: 'text/csv'});
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', 'analytics.csv');
    a.click();
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Analytics</title>
        </MetaTags>
        <DateSelectRow>
          <PageTitle>Analytics</PageTitle>
          <DatePickerBox>
            <Label>From</Label>
            <CustomDatePickerWrapper>
              <DatePicker
                selected={startDate}
                onSelect={onSelectStartDate} //when day is clicked
                onChange={handleStartDateChange} //only when value has changed
                maxDate={new Date()}
                // UK date format
                dateFormat="dd/MM/yyyy"
              />
            </CustomDatePickerWrapper>
          </DatePickerBox>
          <DatePickerBox>
            <Label>To</Label>
            <CustomDatePickerWrapper>
              <DatePicker
                selected={endDate}
                onSelect={onSelectEndDate} //when day is clicked
                onChange={handleEndDateChange} //only when value has changed
                maxDate={new Date()}
                // UK date format
                dateFormat="dd/MM/yyyy"
              />
            </CustomDatePickerWrapper>
          </DatePickerBox>
          <Link to="#" className="btn btn-light" onClick={onExportCSV}>
            <Icon name="download" size={14} className="bx bx-plus me-1" />{" "}
            Export CSV
          </Link>
        </DateSelectRow>
        <LongLine/>
        <SelectRow>
          {Tabs.map((tab, index) => (
            <Select
              isSelected={index === tabIndex}
              key={index}
              onClick={() => handleSelect(index)}
            >
              <SelectTitle isSelected={index === tabIndex}>
                {tab.title}
              </SelectTitle>
              <Icon size={16}
                name={index === tabIndex ? "check-square" : "square"}
                color={
                  index === tabIndex ? ThemeColors.white : ThemeColors.main
                }
              />
            </Select>
          ))}
        </SelectRow>
        <LongLine />
        <AnalysisData>
          {data.map((item, index) => (
            <EventRow key={index}>
              <EventTitleBox>
                <EventTitle>
                  {isConvertedNewEventName(item.event_name)}
                </EventTitle>
                <EventLine />
              </EventTitleBox>
              <EventCountRow percent={calcPercent(item.count)}>
                <EventCount>{item.count}</EventCount>
              </EventCountRow>
            </EventRow>
          ))}

         {data.length > 0 ? ( 
         <EventRow >
          <LongLine />
            <EventTitleBox>
              <EventTitle>
                TOTAL
              </EventTitle>
            </EventTitleBox>
            <EventTitleBox>
              <EventTitle>
                {calcTotalCount(data)}
              </EventTitle>
            </EventTitleBox>
          </EventRow>
         ) : null}
         
        </AnalysisData>
        {loading ? (
          <LoadingContainer>
            <i
              className="bx bx-loader bx-spin align-middle"
              style={{ fontSize: 46 }}
            ></i>
          </LoadingContainer>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default Dashboard;

const DateSelectRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
`;

const PageTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: ${ThemeColors.main};
  white-space: nowrap;
`;

const CustomDatePickerWrapper = styled.div`
  .react-datepicker__input-container input {
    border: none;
    outline: none;
    width: 100px;
    background: ${ThemeColors.lightGrey};
    font-size: 16px;
    font-weight: 600;
    color: ${ThemeColors.main};
  }
`;

const DatePickerBox = styled.div`
  width: 200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${ThemeColors.lightGrey};
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding: 4px;
`;

const Label = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${ThemeColors.main};
  white-space: nowrap;
`;

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  color: ${ThemeColors.main};
`;

const VerticalLine = styled.div`
  width: 1px;
  height: 20px;
  background: ${ThemeColors.grey};
`;

const Records = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-right: 10px;
  white-space: nowrap;
  color: ${ThemeColors.main};
`;

const SelectRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px 50px;
  margin-top: 20px;
`;

const Select = styled.div<{ isSelected: boolean }>`
  width: 200px;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ isSelected }) =>
    isSelected ? ThemeColors.main : ThemeColors.white};
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
  padding: 8px;
`;

const SelectTitle = styled.div<{ isSelected: boolean }>`
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  color: ${({ isSelected }) =>
    isSelected ? ThemeColors.white : ThemeColors.main};
`;

const LongLine = styled.div`
  width: 100%;
  height: 1px;
  background: ${ThemeColors.grey};
  margin-top: 20px;
`;

const AnalysisData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 10px;
`;

const EventRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
`;

const EventTitleBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 210px;
`;

const EventTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
`;

const EventLine = styled.div`
  width: 100%;
  height: 1px;
  background: ${ThemeColors.grey};
`;

const EventCountRow = styled.div<{ percent: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: ${({ percent }) => percent}%;
  height: 20px;
  background: ${ThemeColors.green};
  border-radius: 4px;
  padding: 4px;
`;

const EventCount = styled.div`
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
`;
