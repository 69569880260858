import moment from "moment";
import { useEffect, useState } from "react";
// @ts-ignore
import InfiniteScroll from "react-infinite-scroller";
import { withRouter, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import styled from "styled-components";
import {
  fetchClaimsBySiteCode,
  fetchClaimsByCompanyCode,
  markClaim,
  sendMessage,
} from "../../store/actions"; // Adjust the import path as necessary
import { IClaim } from "src/interfaces/general";
import ClaimTab from "./ClaimTab";
import TablePage, { makeColumns } from "src/components/Common/TablePage";
import { useTranslation } from "react-i18next";
import EditClaim from "./EditClaim";
const ClaimView = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { claims, loading } = useSelector((state: any) => state.claims);
  const filteredClaims = claims.filter((claim: any) => !claim.resolved);
  const [companyId, setCompanyId] = useState<string>("");
  // const { companyId, siteCode } = useParams();
  const { currentUser } = useSelector((state: any) => state.login);
  const hasPermission = currentUser?.permissions?.claims;
  const [currentClaim, setCurrentClaim] = useState<any>([]);
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);
  const [isAll, setIsAll] = useState<boolean>(true);
  const [claimList, setClaimList] = useState<IClaim[]>([]);
  const [drawerClaim, setDrawerClaim] = useState<Boolean>(false);
  const history = useHistory();
  const deleteButtonDisable = true;
  const editButtonDisable = true;
  const handleIsAll = () => {
    setIsAll(true);
    setClaimList(claims);
  }
  const handleIsAppUsers = () => {
    setIsAll(false);
    setClaimList(filteredClaims);
  }
  const onEditClaim = (claim: any) => {
    setDrawerClaim(true);
    setCurrentClaim(claim);
  };
  const onDelete = (claims: any) => {
    setCurrentClaim(claims);
    setShowConfirmAlert(true);
  };
  const onEdit = (claims: any) => {
    history.push(`/claims/${claims.id}`);
  };
  const defaultSorted: any = [
    {
      dataField: "siteCode", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];
  const onDrawerClaimClose = (value: Boolean) => {
    setDrawerClaim(value);
  };
  useEffect(() => {
    if (currentUser?.LOGIN_METHOD === "COMPANY_LOGIN") {
        if (currentUser.code) {
          setCompanyId(currentUser.code);
          dispatch(fetchClaimsByCompanyCode(currentUser.code));
        }
      } 
    else {
      dispatch(fetchClaimsBySiteCode(currentUser.code, currentUser.siteCode));
    }
  }, [companyId]);

  useEffect(() => {
    setClaimList(claims);
  }, [claims]);

  return (
    <TablePage
      loading={loading}
      metaTitle={t("Claims")}
      data={claimList}
      hasPermission={hasPermission}
      title={t("claims.title")}
      description={`(${claimList.length})`}
      columns={makeColumns(onDelete, onEdit, header, { icon: "reply", onClick: onEditClaim }, deleteButtonDisable, editButtonDisable)}
      pageSize={10}
      totalSize={claimList.length}
      defaultSorted={defaultSorted}
      customTab={
        <>
          <ClaimTab handleIsAll={handleIsAll} handleIsAppUsers={handleIsAppUsers} isAll={isAll} />
        </>
      }
      footer={
        <>
          <EditClaim open={drawerClaim} onClose={onDrawerClaimClose} claim={currentClaim} />
        </>
      }
    />
  );
}

export default withRouter(ClaimView);

const header = [
  {
    dataField: "regNo",
    text: "Re No",
    sort: true,
  },
  {
    dataField: "siteCode",
    text: "Site Code",
    sort: true,
  },
  {
    dataField: "type",
    text: "Typed",
    sort: true,
  },
  {
    dataField: "createdAt",
    text: "Date",
    formatter: (_: any, claim: any) => (
      <span>{moment(claim.createdAt).format("DD/MM/YYYY")}</span>
    ),
  },
  {
    dataField: "resolved",
    text: "Status",
    formatter: (_: any, claim: any) => (
      <span>{claim.resolved ? "resolved" : "pending"}</span>
    ),
    sort: true,
  },
  {
    dataField: "menu",
    editable: false,
    text: "Action"
  }
];
