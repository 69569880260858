import { useEffect, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import {
  getAllCustomersBySiteCode,
  getAllCustomersByCompanyCode,
  deleteCustomerByDocId,
  getAllCustomersAndVehiclesByCompanyCode,
} from "../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
import AddCustomer from "./add-customer";
import { useTranslation } from "react-i18next";
import AddCustomerBooking from "./add-customer-booking";
import TablePage, { makeColumns } from "src/components/Common/TablePage";
import { ICustomer } from "src/interfaces/general";
import { exportToCSV } from "src/helpers/file_helper";
import CustomerTab from "./customer-tab";
import { filter } from "lodash";



const Customers = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { customers, exportCustomers, loading, success } = useSelector((state: any) => state.customer);
  const filteredCustomers = customers.filter((customer: any) => customer.appInstalled === true);
  const { currentUser } = useSelector((state: any) => state.login);
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [customerList, setCustomerList] = useState<ICustomer[]>([]);
  const [currentCustomer, setCurrentCustomer] = useState<any>([]);
  const [drawer, setDrawer] = useState<Boolean>(false);
  const [drawerBooking, setDrawerBooking] = useState<Boolean>(false);
  const [isAdmin, setIsAdmin] = useState<Boolean>(false);
  const [isAllCustomers, setIsAllCustomers] = useState<boolean>(true);
  const [isAppUsers, setIsAppUsers] = useState<boolean>(true);
  const hasPermission = currentUser?.permissions?.customers;
  const [isAll, setIsAll] = useState<boolean>(true);

  const handleIsAll = () => {
    setIsAll(true);
    setCustomerList(customers);
  }
  const handleIsAppUsers = () => {
    setIsAll(false);
    setCustomerList(filteredCustomers);
  }
  const defaultSorted: any = [
    {
      dataField: "siteCode", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  useEffect(() => {
    if (currentUser?.LOGIN_METHOD === "COMPANY_LOGIN") {
      dispatch(getAllCustomersByCompanyCode(currentUser.code));
      setIsAdmin(true);
    }
    if (currentUser?.LOGIN_METHOD === "ADMIN_LOGIN") {
      dispatch(getAllCustomersByCompanyCode(currentUser.companyCode));
      //no longer filtering admin data by site
      //dispatch(getAllCustomersBySiteCode(currentUser.siteCode));
    }
  }, [currentUser]);

  useEffect(() => {
      setCustomerList(customers);
  }, [customers]);

  const onEdit = (customer: any) => {
    history.push(`/customers/${customer.id}`);
  };

  const onDelete = (customer: any) => {
    setCurrentCustomer(customer);
    setShowConfirmAlert(true);
  };

  const confirmDelete = () => {
    setShowConfirmAlert(false);
    dispatch(deleteCustomerByDocId(currentCustomer.id));
    if (success) {
      setShowSuccessAlert(true)
    }
  };

  const onAdd = () => {
    setDrawer(true);
  };

  const onExport = () => {
    dispatch(getAllCustomersAndVehiclesByCompanyCode(currentUser.companyCode ?? currentUser.code))
  }

  useEffect(() => {
    if (exportCustomers && exportCustomers.length > 0) {
      exportToCSV(exportCustomers, "customers", ["password", "pushTokens", "verifyCode", "verifyCount"]);
    }
  }, [exportCustomers]);

  const onAddBooking = (customer: any) => {
    setDrawerBooking(true);
    setCurrentCustomer(customer);
  };

  const onDrawerClose = (value: Boolean) => {
    setDrawer(value);
  };

  const onDrawerBookingClose = (value: Boolean) => {
    setDrawerBooking(value);
  };

  return (
    <TablePage
      loading={loading}
      metaTitle={t("Customers")}
      data={customerList}
      onAdd={onAdd}
      onExport={isAdmin ? onExport : undefined}
      hasPermission={hasPermission}
      title={t("customers.title")}
      description={`(${customerList.length})`}
      columns={makeColumns(onDelete, onEdit, header, { icon: "book-open", onClick: onAddBooking })}
      pageSize={10}
      totalSize={customerList.length}
      defaultSorted={defaultSorted}
      customTab={
        <>
          <CustomerTab handleIsAll={handleIsAll} handleIsAppUsers={handleIsAppUsers} isAll={isAll} />
        </>
      }
      footer={
        <>
          <AddCustomer open={drawer} onDrawerClose={onDrawerClose} />
          <AddCustomerBooking open={drawerBooking} onDrawerBookingClose={onDrawerBookingClose} currentCustomer={currentCustomer} />
        </>
      }
      // Alerts
      confirmAlert={{
        visible: showConfirmAlert,
        onConfirm: confirmDelete,
        onCancel: () => setShowConfirmAlert(false)
      }}
      successAlert={{
        visible: showSuccessAlert,
        onConfirm: () => setShowSuccessAlert(false)
      }}
    />
  );
};

export default withRouter(Customers);

const header = [
  {
    dataField: "avatar",
    text: "Avatar",
    formatter: (_: any, customer: any) => (
      <img
        className="rounded-circle avatar-sm"
        src={customer?.avatar || "https://upload.wikimedia.org/wikipedia/commons/0/09/Man_Silhouette.png"}
        alt=""
      />
    ),
  },
  {
    dataField: "firstName",
    text: "Customer Name",
    formatter: (_: any, customer: any) => (
      <span>{customer?.firstName + " " + customer?.lastName}</span>
    ),
  },
  {
    dataField: "siteCode",
    text: "Site Code",
    sort: true,
  },
  {
    dataField: "phone",
    text: "Phone",
    sort: true,
  },
  {
    dataField: "email",
    text: "Email",
    sort: true,
  },
  {
    dataField: "registered",
    text: "Registered",
    sort: true,
  },
  {
    dataField: "menu",
    editable: false,
    text: "Action"
  }
];
