import { call, put, takeEvery } from "redux-saga/effects";
import { CustomerTypes } from "./actionTypes";
import { IResponse } from "../../interfaces/general";
import {
  getCustomersBySiteCodeSuccess,
  getCustomersBySiteCodeFail,
  addCustomerSuccess,
  addCustomerFail,
  getCustomerByDocIdSuccess,
  getCustomerByDocIdFail,
  getAllCustomersByCompanyCodeSuccess,
  getAllCustomersByCompanyCodeFail,
  updateCustomerByDocIdSuccess,
  updateCustomerByDocIdFail,
  deleteCustomerByDocIdSuccess,
  deleteCustomerByDocIdFail,
  getAllCustomersAndVehiclesByCompanyCodeSuccess,
  getAllCustomersAndVehiclesByCompanyCodeFail
} from "./actions";

import {
  getCustomersBySiteCodeAsync,
  getCustomersByCompanyCodeAsync,
  getCustomersAndVehiclesByCompanyCodeAsync,
  addCustomerAsync,
  getCustomerByDocIdAsync,
  updateCustomerByDocIdAsync,
  deleteCustomerByDocIdAsync,
  inviteCustomerAsync
} from "../../helpers/backend_helper";

function* getCustomersBySiteCode({ payload: siteCode }: any) {
  try {
    const response: IResponse = yield call(getCustomersBySiteCodeAsync, siteCode);
    if (response.success) {
      yield put(getCustomersBySiteCodeSuccess(response.result));
    }
  } catch (error) {
    yield put(getCustomersBySiteCodeFail(error));
  }
}

function* getCustomersByCompanyCode({ payload: companyCode }: any) {
  try {
    const response: IResponse = yield call(getCustomersByCompanyCodeAsync, companyCode);
    if (response.success) {
      yield put(getAllCustomersByCompanyCodeSuccess(response.result));
    }
  } catch (error) {
    yield put(getAllCustomersByCompanyCodeFail(error));
  }
}

function* getCustomersAndVehiclesByCompanyCode({ payload: companyCode }: any) {
  try {
    const response: IResponse = yield call(getCustomersAndVehiclesByCompanyCodeAsync, companyCode);
    if (response.success) {
      yield put(getAllCustomersAndVehiclesByCompanyCodeSuccess(response.result));
    }
  } catch (error) {
    yield put(getAllCustomersAndVehiclesByCompanyCodeFail(error));
  }
}



function* addCustomer({ payload: customer }: any) {
  try {
    const response: IResponse = yield call(addCustomerAsync, customer);

    if (response.success) {
      yield put(addCustomerSuccess(response.result));
      yield call(inviteCustomerAsync, customer);
    }
  } catch (error) {
    yield put(addCustomerFail(error));
  }
}

function* getCustomerByDocId({ payload: docid }: any) {
  try {
    const response: IResponse = yield call(getCustomerByDocIdAsync, docid);
    console.log('response-------------', response);
    if (response.success) {
      yield put(getCustomerByDocIdSuccess(response.result));
    }
  } catch (error) {
    yield put(getCustomerByDocIdFail(error));
  }
}


function* deleteCustomerByDocId({ payload: docid }: any) {
  try {
    const response: IResponse = yield call(deleteCustomerByDocIdAsync, docid);
    yield put(deleteCustomerByDocIdSuccess(response.result));
  } catch (error) {
    yield put(deleteCustomerByDocIdFail(error));
  }
}

function* updateCustomerByDocId({ payload: { docid, customer, history } }: any) {
  try {
    const response: IResponse = yield call(updateCustomerByDocIdAsync, docid, customer);
    if (response.success) {
      yield put(updateCustomerByDocIdSuccess(response.result));
      history.push('/customers')
    }
  } catch (error) {
    yield put(updateCustomerByDocIdFail(error));
  }
}

function* customerSaga() {
  yield takeEvery(CustomerTypes.GET_CUSTOMERS_BY_SITECODE, getCustomersBySiteCode)
  yield takeEvery(CustomerTypes.GET_CUSTOMERS_BY_COMPANYCODE, getCustomersByCompanyCode)
  yield takeEvery(CustomerTypes.GET_CUSTOMERS_AND_VEHICLES_BY_COMPANYCODE, getCustomersAndVehiclesByCompanyCode)

  yield takeEvery(CustomerTypes.ADD_CUSTOMER, addCustomer)
  yield takeEvery(CustomerTypes.GET_CUSTOMER_BY_DOCID, getCustomerByDocId)
  yield takeEvery(CustomerTypes.DELETE_CUSTOMER, deleteCustomerByDocId),
    yield takeEvery(CustomerTypes.UPDATE_CUSTOMER_BY_DOCID, updateCustomerByDocId);
}

export default customerSaga;


