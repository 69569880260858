import React, {useEffect, useState} from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { userRoutes, authRoutes } from "./routes/allRoutes";
import { useSelector } from "react-redux";
import Authmiddleware from "./routes/middleware/Authmiddleware";
import moment from "moment";
import "moment/locale/en-gb";

moment.locale("en-gb");

import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/index";
import NonAuthLayout from "./components/NonAuthLayout";
import NotFound from "./pages/Util/NotFound"
import axios from "axios"
import i18n from "./i18n";
// Import scss
import "./assets/scss/theme.scss";
import "./assets/scss/preloader.scss";
import { Permissions } from "./enum/Permission";

const App = () => {

  const [customerData, setCustomerData] = useState<any>(null)
  const { layoutType } = useSelector((state: any) => ({
    layoutType: state.Layout.layoutType,
  }));

  useEffect(() => {
    getData();
  }, [])

  //get ip address and location.
  const getData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    setCustomerData(res.data)
    switch(res.data.country_code) { 
      case 'RU': 
        let rslang = 'rs'
        i18n.changeLanguage(rslang);
        localStorage.setItem("I18N_LANGUAGE", rslang);
      break;
      default: 
        let enlang = 'eng'
        i18n.changeLanguage(enlang);
        localStorage.setItem("I18N_LANGUAGE", enlang);
    }
  }

  function getLayout() {
    let layoutCls: Object = VerticalLayout;
    switch (layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  const Layout = getLayout();
  return (
    <React.Fragment>
      <Router>
        <Switch>
          {authRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {userRoutes.map((route: any, idx: number) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              permission={route.permission}
              exact
            />
          ))}

          {/* 404 Route */}
          <Route component={NotFound} /> {/* Replace 'NotFound' with your 404 component */}

        </Switch>
      </Router>
    </React.Fragment>
  );
};

export default App;
