import { all, fork } from "redux-saga/effects"
import LayoutSaga from "./layout/saga"
import companySaga from './company/saga'
import ClaimSaga from './claims/saga'
import siteSaga from './site/saga'
import adminSaga from './admin/saga'
import customerSaga from './customer/saga'
import vehicleSaga from "./vehicle/saga"
import bookingSaga from './booking/saga'
import productSaga from "./product/saga"
import geofenceSaga from "./geofence/saga"
import warrantySaga from "./warranty/saga"
import authSaga from "./auth/login/saga"
import scoreData from './score/saga'
import commsSaga from "./comms/saga"
import bookingToolSaga from "./booking-tool/saga"
import directorySaga from "./directory/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(companySaga),
    fork(siteSaga),
    fork(adminSaga),
    fork(customerSaga),
    fork(vehicleSaga),
    fork(bookingSaga),
    fork(productSaga),
    fork(geofenceSaga),
    fork(warrantySaga),
    fork(authSaga),
    fork(scoreData),
    fork(commsSaga),
    fork(bookingToolSaga),
    fork(directorySaga),
    fork(ClaimSaga),
  ])
}
