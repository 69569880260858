import { call, put, takeEvery, Effect } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import {
  fetchClaimsBySiteCode,
  fetchClaimByCompanyCode,
  getCustomerById,
  markClaim,
  sendMessage,
} from "../../helpers/backend_helper"; // Adjust the import path as necessary

function* fetchClaimsBySiteCodeSaga(action: any): Generator<Effect, void, any> {
  try {
    const res = yield call(fetchClaimsBySiteCode, action.payload.companyCode, action.payload.siteCode);
    console.log("fetchClaimsBySiteCodeSaga", res);
    if (res.success) {
      yield put({ type: actionTypes.FETCH_CLAIMS_BY_SITE_CODE_SUCCESS, payload: res.result });
    } else {
      yield put({ type: actionTypes.FETCH_CLAIMS_BY_SITE_CODE_FAILURE, payload: res.error });
    }
  } catch (err) {
    yield put({ type: actionTypes.FETCH_CLAIMS_BY_SITE_CODE_FAILURE, payload: err });
  }
}
function* fetchClaimByCompanyCodeSaga(action: any): Generator<Effect, void, any> {
  try {
    const res = yield call(fetchClaimByCompanyCode, action.payload.companyCode);
    console.log("fetchClaimByCompanyCodeSaga", res);
    if (res.success) {
      yield put({ type: actionTypes.FETCH_CLAIMS_BY_COMPANY_CODE_SUCCESS, payload: res.result });
    } else {
      yield put({ type: actionTypes.FETCH_CLAIMS_BY_COMPANY_CODE_FAILURE, payload: res.error });
    }
  } catch (err) {
    yield put({ type: actionTypes.FETCH_CLAIMS_BY_COMPANY_CODE_FAILURE, payload: err });
  }
}
function* getClaimByIdSaga(action: any): Generator<Effect, void, any> {
  try {
    const res = yield call(getCustomerById, action.payload.customerId);
    yield put({ type: actionTypes.GET_CLAIM_BY_ID_SUCCESS, payload: res.result });
  } catch (err) {
    yield put({ type: actionTypes.GET_CLAIM_BY_ID_FAILURE, payload: err });
  }
}

function* markClaimSaga(action: any): Generator<Effect, void, any> {
  try {
    yield call(markClaim, action.payload.claimId);
    yield put({ type: actionTypes.MARK_CLAIM_SUCCESS, payload: action.payload.claimId });
  } catch (err) {
    yield put({ type: actionTypes.MARK_CLAIM_FAILURE, payload: err });
  }
}

function* sendMessageSaga(action: any): Generator<Effect, void, any> {
  try {
    yield call(sendMessage, action.payload.claimId, action.payload.notes);
    yield put({ type: actionTypes.SEND_MESSAGE_SUCCESS });
  } catch (err) {
    yield put({ type: actionTypes.SEND_MESSAGE_FAILURE, payload: err });
  }
}

export default function* ClaimSaga(): Generator<Effect, void, any> {
  yield takeEvery(actionTypes.FETCH_CLAIMS_BY_SITE_CODE, fetchClaimsBySiteCodeSaga);
  yield takeEvery(actionTypes.FETCH_CLAIMS_BY_COMPANY_CODE, fetchClaimByCompanyCodeSaga);
  yield takeEvery(actionTypes.GET_CLAIM_BY_ID, getClaimByIdSaga);
  yield takeEvery(actionTypes.MARK_CLAIM, markClaimSaga);
  yield takeEvery(actionTypes.SEND_MESSAGE, sendMessageSaga);
}