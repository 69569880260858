import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAuthUser } from "../../store/actions";
import jwtDecode from 'jwt-decode'
import { Permissions } from "src/enum/Permission";
import Unauthorized from "src/pages/Authentication/Unauthorized";

interface AuthLayoutProps {
  component: any;
  layout: any;
  isAuthProtected: any;
  permission?: Permissions;
  path?: string;
  exact?: boolean;
  key?: number;
}

const Authmiddleware = ({
  component,
  layout,
  isAuthProtected,
  permission,
  path,
  exact,
  key,
  ...rest
}: AuthLayoutProps) => {
  const dispatch = useDispatch();
  const Layout = layout;
  const Component = component;

  useEffect(() => {
    const accessToken: any = sessionStorage.getItem('accessToken');
    if (accessToken) {
      const strUser = sessionStorage.getItem("authUser");
      if (strUser) {
        dispatch(setAuthUser(JSON.parse(strUser)));
      }
    }
  }, [])

  const { currentUser } = useSelector((state: any) => state.login) 

  const hasPermission = (): boolean => { 

    if (!permission)
      return true;

    return currentUser?.permissions[permission];
  }

  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthProtected && !sessionStorage.getItem("authUser")) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        } 
        else if (!hasPermission() && props.location.pathname !== '/unauthorised'){
          return (
            <Layout>
            <Unauthorized />
          </Layout>
          );
        }

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

export default Authmiddleware;
