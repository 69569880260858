export const exportToCSV = (data: any[], fileName: string, excludedColumns: string[]) => {
    if (!data || !data.length) {
        return;
    }

    // Extract headers (object keys)
    let headers = Object.keys(data[0]);

    if (excludedColumns) {
        headers = Object.keys(data[0]).filter(key => !excludedColumns.includes(key));
    }

    // Generate CSV content
    const csvContent = [
        headers.join(','),
        ...data.map(row =>
            headers.map(fieldName => escapeCSVField(row[fieldName])).join(',')
        )
    ].join('\r\n');

    // Trigger CSV download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', `${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

function escapeCSVField(field: any) {
    // Convert Unix epoch timestamps (in milliseconds) to Date objects
    if (typeof field === 'number' && field.toString().length === 13) {
        field = new Date(field);
    }

    // Convert Date objects to a readable string format
    if (field instanceof Date) {
        field = field.toISOString();
    }

    // Convert array of objects (or any array) to a JSON string
    if (Array.isArray(field)) {
        if (field.length > 0) {
            field = JSON.stringify(field);
        } else {
            field = '';
        }
    }

    if (field === null || field === undefined) {
        return '';
    }

    const stringField = String(field);
    // Escape fields that contain commas, double quotes, or line breaks
    if (/["',\n]/.test(stringField)) {
        return `"${stringField.replace(/"/g, '""')}"`; // Escape double quotes
    }
    return stringField;
}

