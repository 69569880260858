



export const ThemeColors = {
    white: 'white',
    grey: '#707070',
    blue: '#4338ca',
    green: '#56c4b7',
    main: '#1e2e5c',
    transparent: 'transparent',
    lightGreen: '#d5e1e5',
    success: '#0BC811',
    extraLightGrey: '#f8f8f8',
    lightGrey: '#eaeaea',
    darkGrey: '#8f8f8f',
    yellow: '#F7B900',
    red: '#C8120B',
    pink: 'rgb(200, 40, 80)',
    purple: '#A868D7'
}