import { CardTitle } from 'reactstrap';
import Select from "react-select";
import useProducts, { IProduct } from 'src/hooks/useProducts';
import { useState } from 'react';
import { useSelector } from 'react-redux';


interface IProps {
    label?: string;
    regNo?: string;
    mileAge?: number;
    value?: IProduct;
    drive?: boolean;
    onSelect: (product?: IProduct) => void;
}

interface IOption {
    value: string;
    label: string;
}

const ProductList = ({
    label,
    value,
    regNo,
    mileAge,
    drive,
    onSelect
}: IProps) => {
    const { currentUser } = useSelector((state: any) => {
        return state.login
    });
    const site = useSelector((state: any) => state.site?.sites?.find((site: any) => site.code === currentUser?.siteCode));

    const productToOption = (product: IProduct): IOption => ({
        value: product.id,
        label: product.wasInfo.ProductName + ` (${product.wasInfo.MonthlyDuration})`
    });

    const [option, setOption] = useState<IOption | undefined>(value ? productToOption(value) : undefined);
    const { filterProducts } = useProducts(regNo, mileAge, drive);
    const options = filterProducts
        .filter(product => !currentUser?.siteCode || product.hendyInfo.siteCode === currentUser?.siteCode)
        .filter(product=>!product.id.includes("-12"))
        .map(productToOption);

    const onChange = (item: IOption) => {
        setOption(item);
        onSelect(filterProducts.find(product => product.id == item.value));
    }

    return (
        <>
            <CardTitle className="py-2">{label || "Please select a product"}</CardTitle>
            <Select
                value={option}
                onChange={onChange}
                options={options}
                isSearchable={true}
            />
        </>
    )
}

export default ProductList;
