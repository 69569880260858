import { combineReducers } from "redux"
import Layout from "./layout/reducer";
import Chat from "./chat/reducer";
import claims from "./claims/reducer";
import contacts from "./contacts/reducer";
import login from "./auth/login/reducer";
import register from "./auth/register/reducer";
import profile from "./auth/profile/reducer";
import forgetPassword from "./auth/forgetpwd/reducer";
import company from './company/reducer'
import site from './site/reducer'
import admin from './admin/reducer'
import customer from './customer/reducer'
import vehicle from './vehicle/reducer'
import booking from './booking/reducer'
import product from './product/reducer'
import geofence from './geofence/reducer'
import warranty from './warranty/reducer'
import score from './score/reducer'
import comms from './comms/reducer'
import bookingTool from './booking-tool/reducer'
import directory from "./directory/reducer";

const rootReducer = combineReducers({
  Layout,
  Chat,
  contacts,
  login,
  register,
  profile,
  forgetPassword,
  company,
  site,
  admin,
  customer,
  vehicle,
  booking,
  product,
  geofence,
  warranty,
  score,
  comms,
  bookingTool,
  directory,
  claims
})

export default rootReducer
